/*
  Imports
*/
import { Container, Grid, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import ListPageTitle from 'src/components/misc/ListPageTitle';
import Page from '../components/Page';
import Selector from 'src/components/Selector';
import rankingsService from 'src/services/RankingsServiceClass';
import RankingsList from './tables/RankingsList';
import { useNavigate } from 'react-router-dom';
import { SETTINGS } from 'src/config/settings';
import FilterComponent from 'src/components/FilterComponent';
import FullScreenDialog from 'src/components/misc/alerts/FullScreenDialog';
import ApexCharts from 'react-apexcharts';
import CenterLoading from 'src/components/misc/CenterLoading';
import AreaCharts from 'src/charts/AreaCharts';
import palette from 'src/theme/palette';
import Countdown from 'src/components/Countdown';
import WeeklyCountdown from 'src/components/WeeklyCountdown';
import MonthlyCountdown from 'src/components/MonthlyCountdown';
import YearlyCountdown from 'src/components/YearlyCountdown';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    nextRewardText: {
        marginBottom: theme.spacing(1),
        textAlign: 'center',
        color: 'text.primary',
        fontFamily: 'Alegreya Sans SC',
        fontSize: '3rem',
        fontWeight: 'bold',
    },
}));

export default () => {
    /*
    States, Params, Navigation, Query, Variables.
  */
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [summary, setSummary] = useState([]);
    const [summaryLoading, setSummaryLoading] = useState(true);
    const [date, setDate] = useState(new Date());
    const [selector, setSelector] = useState(SETTINGS.SELECTOR[0]);
    const [selectedFaction, setSelectedFaction] = useState('');
    const [selectedClass, setSelectedClass] = useState('');
    const [openRow, setOpenRow] = useState(null);

    const navigate = useNavigate();

    const title = 'Rankings';

    function getDates() {
        let to = date;
        let from = date;
        if (selector == 'Weekly') {
            //Get current week day and set the weekdat to monday
            let day = date.getDay();
            let diff = date.getDate() - day + (day == 0 ? -6 : 1);
            from = new Date(date.setDate(diff));
            to = new Date(date.setDate(diff + 6));
        }

        if (selector == 'Monthly') {
            //Get current month and set the month to 1
            from = new Date(date.getFullYear(), date.getMonth(), 1);
            to = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        }
        if (selector == 'Yearly') {
            //Get current year and set the year to 1
            from = new Date(date.getFullYear(), 0, 1);
            to = new Date(date.getFullYear(), 11, 31);
        }

        console.log('Month');

        from = `${from.getFullYear()}-${(from.getMonth() + 1).toString().padStart(2, '0')}-${from
            .getDate()
            .toString()
            .padStart(2, '0')}`;
        to = `${to.getFullYear()}-${(to.getMonth() + 1).toString().padStart(2, '0')}-${to
            .getDate()
            .toString()
            .padStart(2, '0')}`;
        return { to, from };
    }

    const getData = () => {
        let { to, from } = getDates();

        setLoading(true);
        rankingsService
            .getRankings({ from, to, Side: selectedFaction, Class: selectedClass })
            .then((res) => {
                console.log('Res', res);
                setData(res.rankings);
            })
            .catch((error) => {
                if (error.response) if (error.response.status === 401) navigate('/401');
                console.log('ERROR', error);
                // navigate('/404');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getSummary = (CharacterID) => {
        const { to, from } = getDates();

        setSummaryLoading(true);
        rankingsService
            .getSummary(CharacterID, {
                from,
                to,
                Side: selectedFaction,
                Class: selectedClass,
            })
            .then((res) => {
                console.log('Summary', res);
                console.log(
                    'Res Kills',
                    res.summary.map((item) => item.Date),
                );

                setSummary(res.summary);
            })
            .catch((error) => {
                if (error.response) if (error.response.status === 401) navigate('/401');
                console.log('ERROR', error);
                // navigate('/404');
            })
            .finally(() => {
                setSummaryLoading(false);
            });
    };

    const handleClick = (CharacterID) => {
        if (selector !== 'Daily') {
            setOpenRow(openRow === CharacterID ? null : CharacterID);
            getSummary(CharacterID);
        }
    };

    const chartData = [
        {
            name: 'Kills',
            type: 'area',
            data: summary.map((item) => item.Kills),
            color: palette.primary.main,
        },
    ];
    /*
      Use Effect Hooks.
    */

    useEffect(getData, [date, selectedClass, selectedFaction, selector]);

    /*
      Handlers, Functions
    */

    /*
          Use Effect Hooks.
        */

    return (
        <Page title={title}>
            <Container>
                <ListPageTitle mb={5}>{title}</ListPageTitle>
                <Selector
                    selectedDate={date}
                    setSelectedDate={setDate}
                    selector={selector}
                    setSelector={setSelector}
                />
                <br />
                {selector == 'Daily' && (
                    <>
                        <div className={classes.nextRewardText}>Next Reward in</div> <Countdown />
                    </>
                )}
                {selector == 'Weekly' && (
                    <>
                        <div className={classes.nextRewardText}>Next Reward in</div>
                        <WeeklyCountdown />
                    </>
                )}
                {selector == 'Monthly' && (
                    <>
                        <div className={classes.nextRewardText}>Next Reward in</div>
                        <MonthlyCountdown />
                    </>
                )}
                {selector == 'Yearly' && (
                    <>
                        <div className={classes.nextRewardText}>Next Reward in</div>
                        <YearlyCountdown />
                    </>
                )}

                <br />
                <br />
                <FilterComponent
                    selectedFaction={selectedFaction}
                    setSelectedFaction={setSelectedFaction}
                    selectedClass={selectedClass}
                    setSelectedClass={setSelectedClass}
                    getData={getData}
                />
                <br />
                <RankingsList
                    data={data}
                    loading={loading}
                    handleClick={handleClick}
                    openRow={openRow}
                    summary={summary}
                    summaryLoading={summaryLoading}
                    chartData={chartData}
                    selector={selector}
                />

                {/* <FullScreenDialog
                    buttonText={'Close'}
                    openDialog={openDia}
                    handleButton={handleClose}
                    style={{ color: 'white' }}
                    noIcon
                >
                    {`Summary`}
                    {!summaryLoading && !!summary ? (
                        <AreaCharts
                            labels={summary.map((item) => item.Date)}
                            data={chartData}
                            type={['gradient']}
                        />
                    ) : (
                        <>
                            <br />
                            <br />
                            <CenterLoading />
                        </>
                    )}
                </FullScreenDialog> */}
            </Container>
        </Page>
    );
};
