/*
    Imports
*/
import { Icon } from '@iconify/react';
import {
    Alert,
    Collapse,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Cookies from 'js-cookie';
import Dialog from 'src/components/misc/alerts/Dialog';
import LoadingFormButton from 'src/components/misc/Buttons/LoadingFormButton';
import { MigrationSchema } from 'src/config/form-schemas';
import { LockIcon } from 'src/config/icons';
import { SETTINGS } from 'src/config/settings';
import userService from 'src/services/UserServiceClass';

/*
    Main Working
*/
export default ({ }) => {
    /*
      States, Params, Navigation, Query.
    */
    const [serverError, setServerError] = useState('');
    const [waitError, setWaitError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [openDia, setOpenDia] = useState(null);

    const navigate = useNavigate();
    const location = useLocation();

    /*
      Form Setup
    */
    const formik = useFormik({
        initialValues: {
            code: '',
            game: localStorage.getItem('selectedGame') || SETTINGS.GAMES[0].id,
            company: SETTINGS.COMPANY[0],
        },
        validationSchema: MigrationSchema,
        onSubmit: (values, { setSubmitting, setFieldError }) => {
            //Set Cookie to allow 3 requests every 15 mins.

            const cookieValue = Cookies.get('migration');
            cookieValue
                ? Cookies.set('migration', parseInt(Cookies.get('migration')) + 1, {
                    path: '/',
                    expires: new Date(new Date().getTime() + 900 * 1000),
                })
                : Cookies.set('migration', 1, {
                    path: '/',
                    expires: new Date(new Date().getTime() + 900 * 1000),
                });

            if (cookieValue >= 3) {
                setServerError(
                    'You have exceeded the limit of 3 requests in 15 minutes. Please try again later.',
                );
                setSubmitting(false);
                setWaitError(true);
                return;
            }

            userService
                .migration({
                    code: values.code,
                    game: values.game + '',
                    company: values.company,
                })
                .then((data) => {
                    setOpenDia(data.msg)
                })
                .catch((err) => {
                    console.error('Migration Error', err);

                    if (!err.response) {
                        setServerError('Error occurred please try later');
                    } else {
                        setServerError(err.response.data.msg);
                    }
                })
                .finally(() => setSubmitting(false));
        },
    });
    const { errors, touched, isSubmitting, handleSubmit, getFieldProps, handleChange, resetForm } =
        formik;

    const handleClose = () => {
        resetForm();
        setOpenDia(false);
    };

    /*
      Use Effect Hooks.
    */

    /*
      Main Design
    */
    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Grid container sx={{ display: 'block' }} spacing={2}>
                    {/* <Grid item xs={12} md={6}>
                        <Typography variant="h5" sx={{ marginTop: 2 }}>
                            COMPANY
                        </Typography>
                        <Typography sx={{ marginBottom: 3 }}>
                            Select the Company from where you are migrating from.
                        </Typography>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Company</InputLabel>
                            <Select
                                defaultValue={SETTINGS.COMPANY[0]}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Company"
                                {...getFieldProps('company')}
                            >
                                {SETTINGS.COMPANY.map((a) => {
                                    return (
                                        <MenuItem key={a} value={a}>
                                            {a}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid> */}
                    <Grid item xs={12} md={6}>
                        <Typography variant="h5" sx={{ marginTop: 2 }}>
                            GAME
                        </Typography>
                        <Typography sx={{ marginBottom: 3 }}>
                            Select the Game from where you are migrating from.
                        </Typography>
                        <FormControl fullWidth>
                            <InputLabel id="game-select-label">GAME</InputLabel>

                            <Select
                                // defaultValue={SETTINGS.GAMES[0]}
                                labelId="game-select-label"
                                id="game-select"
                                label="Game"
                                {...getFieldProps('game')}
                            >
                                {SETTINGS.GAMES.map((a) => {
                                    return (
                                        <MenuItem key={a.id} value={a.id}>
                                            {a.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Typography variant="h5" sx={{ marginTop: 2 }}>
                            CODE
                        </Typography>
                        <Typography sx={{ marginBottom: 3 }}>
                            Input the code provided by the Company from where you are migrating
                            from. If you need help retrieving it, please, contact their support.
                        </Typography>
                        <TextField
                            fullWidth
                            autoComplete="current-code"
                            type="text"
                            label="Code"
                            {...getFieldProps('code')}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Icon
                                            icon={LockIcon}
                                            inline="true"
                                            style={{ fontSize: 20 }}
                                        />
                                    </InputAdornment>
                                ),
                            }}
                            error={Boolean(touched.code && errors.code)}
                            helperText={touched.code && errors.code}
                        />
                    </Grid>
                </Grid>

                <br />
                <Grid item xs={12} sm={12} md={3}>
                    {' '}
                    <Dialog
                        buttonText={'Close'}
                        openDialog={!!openDia}
                        handleButton={handleClose}
                        style={{ color: 'white' }}
                    >
                        <div dangerouslySetInnerHTML={{ __html: openDia }}></div>
                        {/* {openDia} */}
                    </Dialog>
                    <LoadingFormButton
                        style={{ padding: '0px 70px' }}
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                        disabled={waitError}
                    >
                        Migrate
                    </LoadingFormButton>
                </Grid>

                {serverError && (
                    <Stack sx={{}} marginTop={3}>
                        <Collapse in={!!serverError}>
                            <Alert severity="error">{serverError}</Alert>
                        </Collapse>
                    </Stack>
                )}
            </Form>
        </FormikProvider>
    );
};
