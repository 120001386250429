import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';
// material
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
// components
import { useState } from 'react';
import Auth from 'src/components/authentication/login/Auth';
import { RouteDefaultPage } from 'src/config/routes';
import { SETTINGS } from 'src/config/settings';
import userService from 'src/services/UserServiceClass';
import { SendLoginTokensToParent } from 'src/utils/misc';

// ----------------------------------------------------------------------

const HeaderStyle = styled('header')(({ theme }) => ({
    top: 0,
    left: 0,
    lineHeight: 0,
    width: '100%',
    position: 'absolute',
    padding: theme.spacing(3, 3, 0),
    [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(5, 5, 0),
    },
}));

// ----------------------------------------------------------------------

export default function LogoOnlyAuthLayout({ rank, loginPage }) {
    const navigate = useNavigate();

    const [showPage, setShowPage] = useState(!loginPage);
    const [searchParams, setSearchParams] = useSearchParams();

    const handleOnAuth = (pass) => {
        if (!pass) {
            return setShowPage(true);
        }

        if (window.opener) {
            SendLoginTokensToParent(window.opener);
            return;
        }

        if (searchParams.get('nonce')) {
            userService
                .freshdeskLogin(searchParams.get('nonce'))
                .then((res) => {
                    window.location.href = `${searchParams.get(
                        'redirect_uri',
                    )}?state=${searchParams.get('state')}&id_token=${res.token}`;
                })
                .catch((err) => {
                    console.error('Error', err);
                });
            return;
        }
        if (loginPage) return navigate(RouteDefaultPage);
    };
    const [open, setOpen] = useState(false);

    return (
        <Auth rank={rank} onAuth={handleOnAuth}>
            {/* <DashboardNavbar onOpenSidebar={() => setOpen(true)} /> */}
            {/* <AuthSideBar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} /> */}

            {showPage && (
                <>
                    <br />

                    <br />

                    <HeaderStyle>
                        <Box
                            sx={{
                                display: { xs: 'block', md: 'none', lg: 'none' },
                            }}
                        >
                            {/* <Logo /> */}
                        </Box>
                    </HeaderStyle>
                    <br></br>
                    <Outlet />
                </>
            )}
            {/* <Footer /> */}
        </Auth>
    );
}
