import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    countdown: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },
    },
    digit: {
        fontSize: '4rem',
        fontWeight: 'bold',
        margin: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            fontSize: '3rem',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '3rem',
        },
    },
    label: {
        fontSize: '1.5rem',
        textTransform: 'uppercase',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '1rem',
        },
    },
}));

export default function MonthlyCountdown() {
    const classes = useStyles();
    const [remainingTime, setRemainingTime] = useState(0);

    useEffect(() => {
        const calculateRemainingTime = () => {
            const now = moment.utc(); // Get current time in UTC 0
            const endOfMonth = moment.utc().endOf('month');
            const timeLeft = endOfMonth.diff(now);
            setRemainingTime(timeLeft);
        };

        calculateRemainingTime(); // Initial calculation

        const timer = setInterval(() => {
            const now = moment.utc(); // Get current time in UTC 0
            const endOfMonth = moment.utc().endOf('month');

            // Reset the countdown if the current time is beyond the end of the month
            if (now.isAfter(endOfMonth)) {
                calculateRemainingTime();
            } else {
                const timeLeft = endOfMonth.diff(now);
                setRemainingTime(timeLeft);
            }
        }, 60000); // Recalculate every second

        return () => {
            clearInterval(timer); // Clean up the timer
        };
    }, []);

    const formatTime = (time) => {
        const duration = moment.duration(time);
        const days = duration.days();
        const hours = duration.hours();
        const minutes = duration.minutes();

        return { days, hours, minutes };
    };

    const renderCountdown = () => {
        const { days, hours, minutes } = formatTime(remainingTime);

        return (
            <div className={classes.countdown}>
                <Typography variant="h2" className={classes.digit}>
                    {days.toString().padStart(2, '0')}
                </Typography>
                <Typography variant="body1" className={classes.label}>
                    Days
                </Typography>
                <Typography variant="h2" className={classes.digit}>
                    {hours.toString().padStart(2, '0')}
                </Typography>
                <Typography variant="body1" className={classes.label}>
                    Hours
                </Typography>
                <Typography variant="h2" className={classes.digit}>
                    {minutes.toString().padStart(2, '0')}
                </Typography>
                <Typography variant="body1" className={classes.label}>
                    Minutes
                </Typography>
            </div>
        );
    };

    return <div className={classes.root}>{renderCountdown()}</div>;
}
