import React from 'react';
import { Tooltip, Grid } from '@mui/material';
import { withStyles } from '@mui/styles';

const customStepperStyle = {
    border_glow: {
        boxShadow: '0px 0px 15px 4px rgba(122,118,22,0.8)',
        position: 'absolute',
    },
};

function RankTooltip({ image, description, chest, classes }) {
    return (
        <Grid container justifyContent={'center'}>
            <Tooltip
                title={
                    <div style={{ padding: '10px' }}>
                        <div style={{ display: 'flex' }}>
                            <img
                                src={image}
                                height={30}
                                style={{
                                    display: 'inline',
                                    marginRight: '10px',
                                    maxWidth: 30,
                                }}
                            />
                            <span
                                style={{
                                    fontWeight: 'bold',
                                    fontSize: '1.1em',
                                    marginTop: '10px',
                                }}
                            >
                                {chest}
                            </span>
                        </div>
                        <div style={{ margin: '10px 0px' }}>
                            <span>{description}</span>
                        </div>
                        <img src={image} style={{ textAlign: 'center' }} />
                    </div>
                }
                arrow
            >
                <img
                    src="/static/images/store/imageFrame.png"
                    height={40}
                    width={40}
                    className={classes.border_glow}
                />
            </Tooltip>
            <img src={image} height={30} width={30} style={{ marginTop: '5px' }} />
        </Grid>
    );
}

export default withStyles(customStepperStyle)(RankTooltip);
