import { Stack, Typography } from '@mui/material';

export default ({ children, mb }) => {
    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={mb}>
            <Typography variant="h4" gutterBottom>
                {children}
            </Typography>
        </Stack>
    );
};
