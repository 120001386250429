// src/components/VersionCheckWrapper.js
import React from 'react';
import useVersionCheck from '../hooks/useVersionCheck';

const VersionCheckWrapper = ({ children }) => {
    useVersionCheck();
    return children;
};

export default VersionCheckWrapper;
