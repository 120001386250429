export const RouteLandingPage = '/';
export const RouteForgetPass = '/recovery';
export const RouteResetPass = '/reset';
export const RouteLogin = '/login';
export const RouteMigration = '/migration';
export const RouteTransaction = '/transactions';
export const RouteRanking = '/rankings';
export const RouteGemStore = '/store';
export const RouteCharacterOptimization = '/characteroptimization';

export const RouteGems = '/gems';
export const RouteAccounts = '/accounts';
export const Route401 = '/401';
export const RouteHome = 'https://fawkesgames.com/';
export const RouteDiscord = 'https://discord.gg/fawkes';
export const RouteLogout = '/logout';
export const RouteRegister = '/register';
export const RouteSecurity = '/security';
export const RouteRedeemCode = '/redeemcode';
export const RouteLoyaltyCards = '/loyalty-cards';
export const RouteShaiya = 'https://fawkesgames.com/shaiya/';
export const RouteLastChaos = 'https://fawkesgames.com/last-chaos/';
export const RouteNews = 'https://fawkesgames.com/news/';
// export const RouteStore = 'https://fawkesgames.com/store/';
export const RouteStore = RouteGems;
export const RouteTerms = 'https://fawkesgames.com/terms/';
export const RoutePrivacy = 'https://fawkesgames.com/privacy/';
export const RouteCompnay = 'https://fawkes.breezy.hr/';
export const RouteAboutUs = 'https://fawkesgames.com/about-us/';
export const RouteSupport = 'https://fawkes.freshdesk.com/support/home';
export const RouteCookies = 'https://fawkesgames.com/cookies/';
export const RouteLinkedIn = 'https://www.linkedin.com/company/fawkes-games';
export const RouteFacebook = 'https://www.facebook.com/fawkes.team';

export const RouteDefaultPage = RouteAccounts;
