import PropTypes from 'prop-types';
// material
import { Box } from '@mui/material';
import { SETTINGS } from 'src/config/settings';

// ----------------------------------------------------------------------

FooterLogo.propTypes = {
    sx: PropTypes.object,
};

export default function FooterLogo({
    sx,
    path = SETTINGS.LogoImage,
    link,
    resProp,
    height,
    width,
}) {
    return (
        <a href={link} target="_blank" rel="noreferrer">
            <Box
                margin="auto"
                component="img"
                src={path}
                sx={{
                    marginBottom: { xs: resProp, md: 0 },
                    height: height,
                    width: width,
                    objectFit: 'cover',
                    ...sx,
                }}
            />
        </a>
    );
}
