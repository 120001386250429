/*
  Imports
*/
import {
    Card,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
} from '@mui/material';
import { useState } from 'react';
import CenterLoading from 'src/components/misc/CenterLoading';
import Label from 'src/components/misc/Label';
import { SETTINGS } from 'src/config/settings';
import palette from 'src/theme/palette';
import { getDateString } from 'src/utils/dateTime';
import { fNumber } from 'src/utils/formatNumber';
import SearchNotFound from '../../components/misc/alerts/SearchNotFound';
import { UserListHead, UserListToolbar } from '../../components/misc/table';
import Scrollbar from '../../components/Scrollbar';

/*
  Global Variables, Functions
*/

/*
  Main Working
*/
export default ({ data, pagination, setPagination, loading, count }) => {
    const TableHeader = [
        { id: 'PaymentUID', label: 'ID', align: 'left' },
        { id: 'Date', label: 'Date', align: 'left' },
        { id: 'game', label: 'Game', align: 'left' },
        { id: 'amount', label: 'Amount', align: 'left' },
        { id: 'status', label: 'Status', align: 'left' },
    ];

    const [selected, setSelected] = useState([]);
    const [search, setSearch] = useState(pagination.search);
    const emptyRows = pagination.page > 0 ? data.length : 0;
    const isUserNotFound = data.length === 0;

    const handleRequestSort = (_event, property) => {
        if (property == 'amount' || property == 'status') return;
        const isDesc = pagination.sort_by == property && pagination.order == 'desc';
        setPagination({ ...pagination, sort_by: property, order: isDesc ? 'asc' : 'desc' });
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = data.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (_event, newPage) => {
        setPagination({ ...pagination, page: newPage });
    };

    const handleChangePerPage = (event) => {
        setPagination({ ...pagination, perPage: parseInt(event.target.value, 10), page: 0 });
    };

    const handleSearchInput = (event) => {
        setSearch(event.target.value);
    };

    const handleSearch = (event) => {
        if (event.keyCode == 13 && search != pagination.search) {
            setPagination({ ...pagination, search, page: 0 });
        }
    };
    return (
        <>
            {loading ? (
                <CenterLoading />
            ) : (
                <>
                    <Card>
                        <UserListToolbar
                            numSelected={selected.length}
                            filterName={search}
                            onFilterName={handleSearchInput}
                            handleSearch={handleSearch}
                        />
                        <Scrollbar>
                            <TableContainer sx={{ minWidth: 800 }}>
                                <Table>
                                    <UserListHead
                                        order={pagination.order}
                                        orderBy={pagination.sort_by}
                                        headLabel={TableHeader}
                                        rowCount={data.length}
                                        numSelected={selected.length}
                                        onRequestSort={handleRequestSort}
                                        onSelectAllClick={handleSelectAllClick}
                                        hideCheckBoxes={true}
                                    />
                                    <TableBody>
                                        {data.map((row) => {
                                            const {
                                                PaymentUID: id,
                                                Date: date,
                                                Data: order,
                                                Game,
                                            } = row;

                                            const game = SETTINGS.GAMES[Game].name;
                                            const { status, currency, amount, currency_type } =
                                                order;
                                            const isItemSelected = selected.indexOf(id) !== -1;

                                            return (
                                                <TableRow
                                                    hover
                                                    key={id}
                                                    tabIndex={-1}
                                                    role="checkbox"
                                                    selected={isItemSelected}
                                                    aria-checked={isItemSelected}
                                                    style={{
                                                        backgroundColor: palette.background.hover,
                                                        textDecoration: 'none',
                                                    }}
                                                >
                                                    <TableCell padding="checkbox"></TableCell>
                                                    <TableCell align="left"> {id}</TableCell>
                                                    <TableCell align="left">
                                                        {getDateString(date)}
                                                    </TableCell>
                                                    <TableCell align="left"> {game}</TableCell>

                                                    <TableCell align="left">
                                                        {' '}
                                                        {fNumber(
                                                            amount,
                                                            Number.isInteger(amount)
                                                                ? '0,0'
                                                                : '0,0.00',
                                                        )}{' '}
                                                        {currency_type == 'real'
                                                            ? currency
                                                            : 'GEMS'}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Label
                                                            variant="ghost"
                                                            color={
                                                                (status != 'paid' && 'error') ||
                                                                (status == 'paid' && 'success')
                                                            }
                                                        >
                                                            {status}
                                                        </Label>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                        {emptyRows > 0 && (
                                            <TableRow style={{ emptyRows }}>
                                                <TableCell colSpan={TableHeader.length + 1} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                    {isUserNotFound && (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell
                                                    align="center"
                                                    colSpan={TableHeader.length + 1}
                                                    sx={{ py: 3 }}
                                                >
                                                    <SearchNotFound searchQuery={search} />
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    )}
                                </Table>
                            </TableContainer>
                        </Scrollbar>

                        <TablePagination
                            rowsPerPageOptions={SETTINGS.rowsPerPageList}
                            component="div"
                            count={count}
                            rowsPerPage={pagination.perPage}
                            page={pagination.page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangePerPage}
                        />
                    </Card>
                </>
            )}
        </>
    );
};
