// src/hooks/useVersionCheck.js
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useVersionCheck = () => {
    const location = useLocation();

    useEffect(() => {
        const checkVersion = async () => {
            try {
                const response = await fetch('/version.json');
                const { version } = await response.json();
                const localVersion = localStorage.getItem('appVersion');

                if (+localVersion !== version) {
                    localStorage.setItem('appVersion', version);
                    window.location.reload(true);
                }
            } catch (error) {
                console.error('Error fetching version:', error);
            }
        };

        checkVersion();
    }, [location]); // Dependency on location to trigger on every page change
};

export default useVersionCheck;
