/*
    Imports
*/
import { Stack, Typography } from '@mui/material';
import LogoOnlyBody from 'src/components/misc/LogoOnlyBody';
import { SETTINGS } from 'src/config/settings';
import { RootStyle } from 'src/theme/logo-only-pages';
import palette from 'src/theme/palette';
import ForgetPasswordForm from './forms/ForgetPasswordForm';

/*
    Main Working
*/
export default () => {
    return (
        <RootStyle title="Password recovery">
            <LogoOnlyBody image={SETTINGS.ForgetPageImage} title="Password Recovery">

                <ForgetPasswordForm />
            </LogoOnlyBody>
        </RootStyle>
    );
};
