/*
  Imports
*/
import { Box, Container, Typography, Grid, Link } from '@mui/material';
import { motion } from 'framer-motion';
import { MotionContainer, varBounceIn } from 'src/components/animate';
import Page from 'src/components/Page';
import { SETTINGS } from 'src/config/settings';
import palette from 'src/theme/palette';
import Logo from '../components/Logo';
import { PageInProgress } from 'src/theme/logo-only-pages';
import Hammer from 'src/components/Hammer';
import WorkInProgress from 'src/components/WorkInProgress';

/*
  Main Working
*/

export default () => {
    return (
        <Page title="Work in Progress">
            <WorkInProgress />
        </Page>
    );
};
