/*
    Imports
*/
import { Icon } from '@iconify/react';
import {
    Alert,
    Collapse,
    Grid,
    InputAdornment,
    Stack,
    TextField,
    Typography,
    IconButton,
    FormControl,
    DialogTitle,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

import { Form, FormikProvider, useFormik } from 'formik';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import dayjs from 'dayjs';
import utcPlugin from 'dayjs/plugin/utc';
import { Box } from '@mui/system';
import Dialog from 'src/components/misc/alerts/Dialog';
import LoadingFormButton from 'src/components/misc/Buttons/LoadingFormButton';
import { AccountSchema } from 'src/config/form-schemas';

import {
    DiscordIcon,
    EmailIcon,
    Active,
    ShowPasswordIcon,
    HidePasswordIcon,
} from 'src/config/icons';
import { countryList } from 'src/constants';
import userService from 'src/services/UserServiceClass';
import ExpiryComponent from 'src/components/ExpiryComponent';

/*
    Main Working
*/

dayjs.extend(utcPlugin);

export default ({ user, getData }) => {
    /*
      States, Params, Navigation, Query.
    */
    const [serverError, setServerError] = useState('');
    const [serverEmailError, setServerEmailError] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const [usernameSent, setUsernameSent] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [openDia, setOpenDia] = useState(false);
    const [openEmailDia, setOpenEmailDia] = useState(false);
    const [email, setEmail] = useState(user.email ?? '');
    const [dob, setDob] = useState('');
    const [password, setPassword] = useState('');

    const navigate = useNavigate();
    const location = useLocation();

    /*
      Form Setup
    */
    const formik = useFormik({
        initialValues: {
            discord: user.discord ?? '',
            country: countryList.find((c) => c.Code == user?.country) ?? countryList[0],
            email: user.email ?? '',
        },
        validationSchema: AccountSchema,
        onSubmit: (values, { setSubmitting, setFieldError }) => {
            userService
                .updateMe({
                    discord: values.discord,
                    country: values.country.Code,
                    email: values.email,
                })
                .then((res) => {
                    if (user.email != values.email) {
                        setOpenEmailDia(true);
                    } else {
                        getData();
                    }
                })
                .catch((err) => {
                    console.error('Error', err);
                    setSubmitting(false);
                    if (!err.response) {
                        setServerError('Error occurred please try later');
                    } else {
                        setServerError(err.response.data.msg);
                    }
                });
        },
    });

    const {
        values,
        errors,
        touched,
        isSubmitting,
        handleSubmit,
        getFieldProps,
        setFieldValue,
        resetForm,
    } = formik;

    const handleClose = () => {
        getData();
        setOpenDia(false);
    };

    const handleEmailSubmit = () => {
        let d = new Date(dob);
        d = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
        userService
            .updateEmail({
                email: values.email,
                password: password,
                dob: d,
            })
            .then((res) => {
                setOpenDia(res.msg);
                setOpenEmailDia(false);
            })
            .catch((err) => {
                console.error('Error', err);
                if (!err.response) {
                    setServerEmailError('Error occurred, please try later');
                } else {
                    setServerEmailError(err.response.data.msg);
                }
            });
    };

    const handleEmailClose = () => {
        getData();
        setOpenEmailDia(false);
    };

    const handleShowPassword = () => {
        setShowPassword((show) => !show);
    };

    return (
        <>
            <br />
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <Typography variant="h6" sx={{ marginBottom: 2 }}>
                                Status
                            </Typography>
                        </Grid>
                        {!user.ban_info ? (
                            <>
                                <Grid item>
                                    <Box display="flex" alignItems="center">
                                        <Icon
                                            icon={Active}
                                            color="#75B158"
                                            height="32"
                                            width="32"
                                        />
                                        <Typography sx={{ marginLeft: 1, fontWeight: 'bold' }}>
                                            Active
                                        </Typography>
                                    </Box>
                                </Grid>
                            </>
                        ) : (
                            <>
                                <Grid item>
                                    <ExpiryComponent
                                        expireDate={user.ban_info.ExpireDate}
                                        reason={user.ban_info.Reason}
                                    />
                                </Grid>
                            </>
                        )}
                        <Grid item xs={12} md={12} sx={{ marginTop: 2 }}>
                            <Typography variant="h6" sx={{ marginBottom: 2 }}>
                                Update Information
                            </Typography>
                        </Grid>

                        <Grid item xs={12} md={6} sx={{ marginTop: { xs: 0, md: 2 } }}>
                            <TextField
                                fullWidth
                                autoComplete="email"
                                type="text"
                                label="Email"
                                {...getFieldProps('email')}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon
                                                icon={EmailIcon}
                                                inline="true"
                                                style={{ fontSize: 20 }}
                                            />
                                        </InputAdornment>
                                    ),
                                }}
                                disabled={false}
                                error={Boolean(touched.email && errors.email)}
                                helperText={touched.email && errors.email}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}></Grid>

                        <Grid item xs={12} md={6} sx={{ marginTop: { xs: 0, md: 1 } }}>
                            <Autocomplete
                                id="highlights-demo"
                                // sx={{ width: 300 }}
                                options={countryList}
                                value={formik.values.country}
                                getOptionLabel={(option) => option?.Name || ''}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Country"
                                        margin="normal"
                                        error={Boolean(touched.country && errors.country)}
                                        helperText={touched.country && errors.country}
                                    />
                                )}
                                onChange={(event, newValue) => {
                                    setFieldValue('country', newValue);
                                }}
                                renderOption={(props, option, { inputValue }) => {
                                    const matches = match(option?.Name, inputValue, {
                                        insideWords: true,
                                    });
                                    const parts = parse(option.Name, matches);

                                    return (
                                        <li {...props}>
                                            <div>
                                                {parts.map((part, index) => (
                                                    <span
                                                        key={index}
                                                        style={{
                                                            fontWeight: part.highlight ? 700 : 400,
                                                        }}
                                                    >
                                                        {part.text}
                                                    </span>
                                                ))}
                                            </div>
                                        </li>
                                    );
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}></Grid>

                        <Grid item xs={12} md={6} sx={{ marginTop: { xs: 0, md: 2 } }}>
                            <TextField
                                fullWidth
                                autoComplete="current-discord"
                                type="text"
                                label="Discord"
                                {...getFieldProps('discord')}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon
                                                icon={DiscordIcon}
                                                inline="true"
                                                style={{ fontSize: 20 }}
                                            />
                                        </InputAdornment>
                                    ),
                                }}
                                error={Boolean(touched.discord && errors.discord)}
                                helperText={touched.discord && errors.discord}
                            />
                        </Grid>
                    </Grid>
                    <Box>
                        {/* <br />
                    <Typography variant="h4" sx={{ letterSpacing: 0.5 }}>
                        Communication
                    </Typography>
                    <Typography
                        sx={{
                            fontWeight: 'normal',
                            fontSize: 14,
                            wordSpacing: 2,
                            letterSpacing: 1,
                        }}
                    >
                        Manage your e-mail preferences.
                    </Typography>
                    <br /> */}
                        {/* <FormGroup>
                        <FormControlLabel
                            control={<Checkbox defaultChecked />}
                            label="Fawkes: promotions, gifts, new releases, etc. (recommended)"
                            value={handleCheckBox}
                            onError={Boolean(touched.checkBox && errors.checkBox)}
                            helperText={touched.discord && errors.discord}
                        />

                        <FormControlLabel
                            control={<Checkbox defaultChecked />}
                            label="Shaiya: notifications, news and general updates for the given game"
                            value={handleCheckBox}
                        />
                        <FormControlLabel
                            control={<Checkbox defaultChecked />}
                            label="Last Chaos: notifications, news and general updates for the given game"
                            value={handleCheckBox}
                        />
                    </FormGroup> */}
                    </Box>

                    <br />

                    <Grid container spacing={0} sx={{ marginTop: 2 }}>
                        <Grid item xs={12} sm={12} md={3} style={{ marginBottom: 20 }}>
                            <LoadingFormButton
                                style={{ padding: '0px 37px' }}
                                size="large"
                                type="submit"
                                variant="contained"
                                loading={isSubmitting}
                            >
                                Update Info
                            </LoadingFormButton>
                        </Grid>
                        {/* <Grid item xs={12} md={6}>
                            <LoadingFormButton
                                size="large"
                                type="button"
                                variant="outlined"
                                loading={submit}
                                onClick={forgetPassword}
                                sx={{ p: '0px 37px' }}
                            >
                                Password Change
                            </LoadingFormButton>
                        </Grid> */}
                        <Grid item xs={12} sm={12} md={3}>
                            <Dialog
                                buttonText={'Close'}
                                openDialog={!!openDia}
                                handleButton={handleClose}
                                style={{ color: 'white' }}
                            >
                                {openDia}
                            </Dialog>
                        </Grid>

                        <Grid item xs={12} sm={12} md={3}>
                            <Dialog
                                buttonText2={'Confirm'}
                                buttonText={'Close'}
                                openDialog={!!openEmailDia}
                                handleButton2={handleEmailSubmit}
                                handleButton={handleEmailClose}
                                noIcon
                            >
                                <DialogTitle>Update Email</DialogTitle>
                                <FormControl fullWidth>
                                    <Grid container spacing={2}>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            sx={{ marginTop: 2 }}
                                        >
                                            {' '}
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    inputFormat="MM/dd/yyyy"
                                                    label="Date of Birth"
                                                    value={dob}
                                                    onChange={(newValue) => {
                                                        setDob(newValue);
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            fullWidth
                                                            {...params}
                                                            error={false}
                                                            helperText={''}
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            sx={{ marginTop: 2 }}
                                        >
                                            <TextField
                                                label="Password"
                                                type={showPassword ? 'text' : 'password'}
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                onClick={handleShowPassword}
                                                                edge="end"
                                                            >
                                                                <Icon
                                                                    icon={
                                                                        showPassword
                                                                            ? HidePasswordIcon
                                                                            : ShowPasswordIcon
                                                                    }
                                                                />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                    </Grid>

                                    {serverEmailError && (
                                        <Stack sx={{}} marginTop={3}>
                                            <Collapse in={!!serverEmailError}>
                                                <Alert severity="error">{serverEmailError}</Alert>
                                            </Collapse>
                                        </Stack>
                                    )}
                                </FormControl>
                            </Dialog>
                        </Grid>
                    </Grid>

                    {serverError && (
                        <Stack sx={{}} marginTop={3}>
                            <Collapse in={!!serverError}>
                                <Alert severity="error">{serverError}</Alert>
                            </Collapse>
                        </Stack>
                    )}
                </Form>
            </FormikProvider>
        </>
    );
};
