import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import { Container, Typography, Grid, Link } from '@mui/material';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    countdown: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },
    },
    digit: {
        fontSize: '4rem',
        fontWeight: 'bold',
        margin: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            fontSize: '3rem',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '3rem',
        },
    },
    label: {
        fontSize: '1.5rem',
        textTransform: 'uppercase',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '1rem',
        },
    },
    reward: {
        marginTop: theme.spacing(2),
        fontSize: '1.5rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
        },
    },
}));

export default function YearlyCountdown() {
    const classes = useStyles();

    const [countdown, setCountdown] = useState({
        months: 0,
        days: 0,
        hours: 0,
    });

    useEffect(() => {
        const calculateCountdown = () => {
            const now = moment.utc(); // Get current time in UTC 0
            const endOfYear = moment.utc().endOf('year');
            const duration = moment.duration(endOfYear.diff(now));

            const currentMonth = now.month(); // Get the current month (0-11)
            const daysInCurrentMonth = moment.utc().month(currentMonth).daysInMonth();

            setCountdown({
                months: duration.months(),
                days: daysInCurrentMonth - now.date(),
                hours: duration.hours(),
            });
        };

        calculateCountdown(); // Initial calculation

        const interval = setInterval(calculateCountdown, 3600000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <div className={classes.countdown}>
            <Typography variant="h2" className={classes.digit}>
                {countdown.months.toString().padStart(2, '0')}
            </Typography>
            <Typography variant="body1" className={classes.label}>
                Months
            </Typography>
            <Typography variant="h2" className={classes.digit}>
                {countdown.days.toString().padStart(2, '0')}
            </Typography>
            <Typography variant="body1" className={classes.label}>
                Days
            </Typography>
            <Typography variant="h2" className={classes.digit}>
                {countdown.hours.toString().padStart(2, '0')}
            </Typography>
            <Typography variant="body1" className={classes.label}>
                Hours
            </Typography>
        </div>
    );
}
