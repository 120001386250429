/*
  Imports
*/
import { Box, Container, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import ListPageTitle from 'src/components/misc/ListPageTitle';
import { SETTINGS } from 'src/config/settings';
import checkoutService from 'src/services/CheckoutServiceClass';
import Page from '../components/Page';
import Transacations from './tables/TransactionsList';
import Hammer from 'src/components/Hammer';
import Xsolla from 'src/components/Xsolla';
import { useNavigate } from 'react-router-dom';

export default () => {
    /*
    States, Params, Navigation, Query, Variables.
  */

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({ data: [], count: 0 });
    const navigate = useNavigate();

    const [pagination, setPagination] = useState({
        search: '',
        perPage: SETTINGS.defaultPerPage,
        page: 0,
        order: 'desc',
        sort: 'PaymentUID',
    });

    const title = 'Transactions';

    /*
      Handlers, Functions
    */
    const getData = () => {
        setLoading(true);
        checkoutService
            .getMyTransactions(pagination)
            .then((res) => {
                setData(res);
            })
            .catch((error) => {
                if (error.response) if (error.response.status === 401) navigate('/401');
                // navigate('/404');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    /*
          Use Effect Hooks.
        */

    useEffect(getData, [pagination]);

    return (
        <Page title={title}>
            <Container>
                <ListPageTitle mb={5}>{title}</ListPageTitle>
                <Transacations
                    data={data.data}
                    count={data.count}
                    pagination={pagination}
                    setPagination={setPagination}
                    loading={loading}
                />
                <Xsolla />
            </Container>
        </Page>
    );
};
