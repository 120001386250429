export function randomColor() {
    let hex = Math.floor(Math.random() * 0xffffff);
    return '#' + hex.toString(16);
}

export function SendLoginTokensToParent(toSend) {
    if (!toSend) {
        return;
    }
    toSend.postMessage(
        {
            token: localStorage.getItem('token'),
            xtoken: localStorage.getItem('xtoken'),
        },
        '*',
    );
    window.close();
}
