import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    InputLabel,
    LinearProgress,
    MenuItem,
    Select,
    Tooltip,
} from '@mui/material';
import Dialog from 'src/components/misc/alerts/Dialog';
import { withStyles } from '@mui/styles';
import userService from 'src/services/UserServiceClass';
import { CheckBox } from '@mui/icons-material';
import { SETTINGS } from 'src/config/settings';

const customStepperStyle = {
    stepper: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-around',
        padding: 0,
        width: '100%',
    },

    stepper__step: {
        position: 'relative',
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-around',
        width: '100%',
    },

    stepper__step__index: {
        width: '20px',
        height: '20px',
        lineHeight: '30px',
        borderRadius: '50%',
        textAlign: 'right',
        marginBottom: '5px',
    },

    stepper__step__label: {
        marginBottom: 10,
        marginLeft: 5,
    },

    stepper_step_unlocked: {
        filter: 'drop-shadow(0px 0px 8px #fff);',
        marginBottom: 10,
        marginLeft: 5,
    },

    stepper_step_locked: {
        filter: 'grayscale(90%)',
        marginBottom: 10,
        marginLeft: 5,
    },

    stepper_step_claimed: {
        marginBottom: 10,
        marginLeft: 5,
    },

    labelContainer: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'center',
    },

    linearProgress: {
        flex: '1 1 auto',
        position: 'absolute',
        top: 8,
        left: 'calc(-50% + 35px)',
        right: 'calc(50% + 45px)',
        height: '0.4em',
    },

    inline: {
        display: 'flex',
    },

    border_glow: {
        boxShadow: '0px 0px 15px 4px rgba(122,118,22,0.8)',
        position: 'absolute',
    },

    border_dull: {
        position: 'absolute',
        zIndex: '9',
        filter: 'grayscale(90%)',
    },

    border_normal: {
        position: 'absolute',
        zIndex: '9',
    },
};

function CustomStepper(props) {
    const { steps, classes, getData, game } = props;
    const [openDia, setOpenDia] = useState(false);
    const [rewardTitle, setRewardTitle] = useState();
    const [server, setServer] = useState();
    const [checked, setChecked] = useState(false);
    const [errorDia, setErrorDia] = useState(false);
    const [error, setServerError] = useState(false);
    const [loading, setLoading] = useState(false);

    const claimReward = (id, status, title) => {
        if (status.includes('unlocked')) {
            setRewardTitle({ title, id });
            setOpenDia(true);
        }
    };

    const handleClose = () => {
        setChecked(false);
        setOpenDia(false);
    };

    const handleClose2 = () => {
        if (rewardTitle && checked) {
            setLoading(true);
            userService
                .claimReward({ id: rewardTitle?.id, game, server })
                .then(() => {
                    getData();
                })
                .catch((err) => {
                    setErrorDia(true);
                    if (!err.response) {
                        setServerError('Error occurred please try later');
                    } else {
                        setServerError(err.response.data.msg);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const imageRenderer = (status, image) => {
        const borderClasses = `${
            status === 'claimed'
                ? image.claimed
                    ? classes.border_glow
                    : classes.border_dull
                : classes.border_normal
        }`;

        return (
            <>
                <Grid key={image.id} container justifyContent={'center'} sx={{ mt: 3 }}>
                    <Tooltip
                        title={
                            <div style={{ padding: '10px' }}>
                                <div style={{ display: 'flex' }}>
                                    <img
                                        src={image.tooltip.image}
                                        height={30}
                                        style={{
                                            display: 'inline',
                                            marginRight: '10px',
                                            maxWidth: 30,
                                        }}
                                    />
                                    <span
                                        style={{
                                            fontWeight: 'bold',
                                            fontSize: '1.1em',
                                            marginTop: '10px',
                                        }}
                                    >
                                        {image.tooltip.title}
                                    </span>
                                </div>
                                <div style={{ margin: '10px 0px' }}>
                                    <span>{image.tooltip.para}</span>
                                </div>
                                <img
                                    src={image.tooltip.image_para}
                                    style={{ textAlign: 'center' }}
                                />
                            </div>
                        }
                        arrow
                    >
                        <img
                            src="/static/images/store/imageFrame.png"
                            height={60}
                            width={60}
                            className={borderClasses}
                            onClick={() => {
                                claimReward(image.id, status, image.tooltip.title);
                            }}
                        />
                    </Tooltip>
                    {status === 'locked' && (
                        <img
                            src={image.file}
                            height={40}
                            width={40}
                            style={{ marginTop: '10px' }}
                        />
                    )}
                    {status === 'unlocked' && (
                        <img
                            src={image.file}
                            height={40}
                            width={40}
                            style={{ marginTop: '10px' }}
                        />
                    )}
                    {status === 'claimed' && (
                        <img
                            src={image.file}
                            height={40}
                            width={40}
                            style={
                                image.claimed
                                    ? { marginTop: '10px' }
                                    : { marginTop: '10px', filter: 'grayscale(90%)' }
                            }
                        />
                    )}
                </Grid>
            </>
        );
    };

    const handleServerChange = (event) => {
        setServer(event?.target?.value);
    };

    function renderStep(step, key) {
        const stepClasses = {
            [classes.stepper__step__index]: true,
        };

        const imageClasses = `${
            step.status === 'unlocked'
                ? classes.stepper_step_unlocked
                : step.status === 'locked'
                ? classes.stepper_step_locked
                : classes.stepper_step_claimed
        }`;

        return (
            <>
                <li className={classes.stepper__step} key={key}>
                    <div className={classes.labelContainer}>
                        <div className={classes.inline}>
                            <span className={stepClasses}>{step.label}</span>
                            <img
                                src="/static/illustrations/gem.png"
                                height={20}
                                className={imageClasses}
                            />
                        </div>
                        {step.images.map((image) => (
                            <>{imageRenderer(step.status, image)}</>
                        ))}
                    </div>
                    {!!key && (
                        <LinearProgress
                            variant="determinate"
                            value={step.progress}
                            classes={{ root: classes.linearProgress, bar: classes.bar }}
                        />
                    )}
                </li>

                <Dialog
                    buttonText={'Cancel'}
                    buttonText2={'Claim'}
                    openDialog={openDia}
                    handleButton={handleClose}
                    handleButton2={handleClose2}
                    style={{ color: 'white' }}
                    width={480}
                    disabledButton2={loading}
                >
                    <Box
                        component="form"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            m: 'auto',
                            width: 'fit-content',
                            alignItems: 'center',
                        }}
                    >
                        <FormControl sx={{ mt: 1, width: '100%' }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={(event) => setChecked(event.target.checked)}
                                    />
                                }
                                label="I accept receiving this reward, and it's not possible to revert my decision."
                                style={{ textAlign: 'left' }}
                            />
                        </FormControl>
                        {SETTINGS.GAMES[game].id === 0 && (
                            <FormControl sx={{ mt: 4, width: '80%' }}>
                                <InputLabel htmlFor="server">Server</InputLabel>
                                <Select
                                    autoFocus
                                    value={server ?? false}
                                    onChange={handleServerChange}
                                    label="server"
                                    inputProps={{
                                        name: 'server',
                                        id: 'server',
                                    }}
                                >
                                    <MenuItem value={false}>Select</MenuItem>
                                    {SETTINGS.GAMES[game].servers.map((server) => (
                                        <MenuItem value={server.value}>{server.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                    </Box>
                </Dialog>
                <Dialog
                    buttonText={'Ok'}
                    openDialog={errorDia}
                    handleButton={() => {
                        setServerError(false);
                        setErrorDia(false);
                    }}
                    error={true}
                    style={{ color: 'white' }}
                >
                    {error}
                </Dialog>
            </>
        );
    }

    return <ul className={classes.stepper}>{steps.map(renderStep)}</ul>;
}

CustomStepper.propTypes = {
    classes: PropTypes.object.isRequired,
    steps: PropTypes.array.isRequired,
};

export default withStyles(customStepperStyle)(CustomStepper);
