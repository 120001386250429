import PropTypes from 'prop-types';
// material
import { Box, Grid, Typography } from '@mui/material';
import { SETTINGS } from 'src/config/settings';

// ----------------------------------------------------------------------

Xsolla.propTypes = {
    sx: PropTypes.object,
};

export default function Xsolla() {
    return (
        <Grid
            container
            direction="row"
            alignItems="end"
            justify="end"
            sx={{ mt: 10, mb: 5 }}
            spacing={1}
        >
            <Grid item md={3}></Grid>
            <Grid item md={7} sm={12} xs={12} sx={{ marginBottom: -1.5 }}>
                <Typography variant="h5" gutterBottom sx={{ textAlign: 'right' }}>
                    POWERED BY
                </Typography>
                <Typography
                    gutterBottom
                    variant="body2"
                    sx={{ textAlign: 'justify', mb: { xs: 7, md: 2, sm: 7 } }}
                >
                    Our online payments are powered by Xsolla, a leading payment platform for the
                    gaming and digital content industries. With a variety of payment options and
                    advanced fraud prevention measures, Xsolla ensures a safe and seamless checkout
                    experience for our customers.
                </Typography>
            </Grid>
            <Grid item md={2} sm={12} xs={12}>
                <Box
                    margin="auto"
                    component="img"
                    src={SETTINGS.Xsolla}
                    sx={{ width: 120, height: 120 }}
                />
            </Grid>
        </Grid>
    );
}
