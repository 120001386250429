import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import { Container, Typography, Grid, Link } from '@mui/material';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    countdown: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },
    },
    digit: {
        fontSize: '4rem',
        fontWeight: 'bold',
        margin: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            fontSize: '3rem',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '3rem',
        },
    },
    label: {
        fontSize: '1.5rem',
        textTransform: 'uppercase',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '1rem',
        },
    },
}));

export default function Countdown() {
    const classes = useStyles();
    const theme = useTheme();
    const [remainingTime, setRemainingTime] = useState(0);

    useEffect(() => {
        const calculateRemainingTime = () => {
            const now = moment.utc(); // Get the current UTC time
            const endOfDay = moment.utc().endOf('day'); // Get the end of the day in UTC time
            const duration = moment.duration(endOfDay.diff(now)); // Calculate the duration between now and end of day

            // Convert the duration to milliseconds
            const timeLeft = duration.asMilliseconds();

            setRemainingTime(timeLeft);
        };

        const timer = setInterval(calculateRemainingTime, 1000);

        return () => {
            clearInterval(timer);
        };
    }, []);

    const formatTime = (time) => {
        const duration = moment.duration(time);
        const hours = duration.hours();
        const minutes = duration.minutes();
        const seconds = duration.seconds();
        return { hours, minutes, seconds };
    };

    const renderCountdown = () => {
        const { hours, minutes, seconds } = formatTime(remainingTime);

        return (
            <div className={classes.countdown}>
                <Typography variant="h2" className={classes.digit}>
                    {hours.toString().padStart(2, '0')}
                </Typography>
                <Typography variant="body1" className={classes.label}>
                    Hours
                </Typography>
                <Typography variant="h2" className={classes.digit}>
                    {minutes.toString().padStart(2, '0')}
                </Typography>
                <Typography variant="body1" className={classes.label}>
                    Minutes
                </Typography>
                <Typography variant="h2" className={classes.digit}>
                    {seconds.toString().padStart(2, '0')}
                </Typography>
                <Typography variant="body1" className={classes.label}>
                    Seconds
                </Typography>
            </div>
        );
    };

    return <div className={classes.root}>{renderCountdown()}</div>;
}
