import { LoadingButton } from '@mui/lab';

export default ({ children, loading, disabled, style, size, type, variant, sx, onClick }) => {
    return (
        <LoadingButton
            onClick={onClick}
            style={style}
            size={size}
            type={type}
            variant={variant}
            sx={sx}
            loading={loading}
            disabled={disabled}
        >
            <span>{children}</span>
        </LoadingButton>
    );
};
