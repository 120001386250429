import { Icon } from '@iconify/react';
import { Container, Grid, Link, Typography } from '@mui/material';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { BackIcon } from 'src/config/icons';
import { RouteForgetPass, RouteHome, RouteLogin, RouteRegister } from 'src/config/routes';
import { CardStyle } from 'src/theme/logo-only-pages';
import palette from 'src/theme/palette';
import { MHidden } from '../@material-extend';
import Logo from '../Logo';

export default ({ children, image, title }) => {
    /*
		Main Design
	*/

    //Get Current URL
    const location = useLocation();
    const currentURL = location.pathname;

    const toShow =
        currentURL === RouteRegister || currentURL === RouteForgetPass || currentURL === RouteLogin;

    let linkComponenet = 'a';

    if (currentURL === RouteRegister || currentURL === RouteForgetPass) {
        linkComponenet = RouterLink;
    }

    const backComponent = (
        <Link
            component={linkComponenet}
            color={palette.error.light}
            style={{ textDecoration: 'none', marginTop: 10 }}
            variant="subtitle2"
            to={RouteLogin}
            href={RouteHome}
            {...(linkComponenet == 'a' && {
                target: '_blank',
                rel: 'noreferrer',
            })}
        >
            <Icon icon={BackIcon} inline="true" style={{ fontSize: 15 }} /> Previous Page
        </Link>
    );

    return (
        <>
            <Container maxWidth="sm">
                <MHidden width="mdDown">
                    <CardStyle sx={{ m: 'auto' }}>
                        {toShow && !window.opener && backComponent}
                        <Grid
                            container
                            direction="row"
                            alignItems="center"
                            sx={{ mt: 3, mb: 5 }}
                            spacing={1}
                        >
                            <Grid item md={2}>
                                <Logo />
                            </Grid>
                            <Grid item md={10} sx={{ marginBottom: -1.5 }}>
                                <Typography variant="h3" gutterBottom>
                                    {title}
                                </Typography>
                            </Grid>
                        </Grid>
                        {children}
                    </CardStyle>
                </MHidden>
                <MHidden width="mdUp">
                    <Grid>{toShow && !window.opener && backComponent}</Grid>
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        sx={{ mt: 3, mb: 5 }}
                        spacing={1}
                    >
                        <Grid item xs={2}>
                            <Logo />
                        </Grid>
                        <Grid item xs={10} sx={{ marginBottom: -1.5 }}>
                            <Typography variant="h3" gutterBottom>
                                {title}
                            </Typography>
                        </Grid>
                    </Grid>
                    {children}
                </MHidden>
            </Container>
        </>
    );
};
