import { Icon } from '@iconify/react';
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ErrorDialogIcon, SuccessDialogIcon, WarningDialogIcon } from 'src/config/icons';
import palette from 'src/theme/palette';

export default ({
    warning,
    error,
    noIcon,
    buttonText,
    buttonText2,
    buttonText3,
    openDialog,
    handleButton,
    handleButton2,
    handleButton3,
    children,
    icon,
    disabledButton1,
    disabledButton2,
    disabledButton3,
    width,
}) => {
    let ico = SuccessDialogIcon;
    let color = palette.success.dialog;
    if (error) {
        ico = ErrorDialogIcon;
        color = palette.error.dialog;
    }

    if (warning) {
        ico = WarningDialogIcon;
        color = palette.warning.dialog;
    }
    if (icon) ico = icon;

    if (noIcon) ico = null;
    return (
        <Dialog
            maxWidth="xl"
            open={openDialog}
            onClose={handleButton}
            aria-labelledby="alert-dialog-title"
            PaperProps={{
                style: {
                    overflow: 'visible',
                    width: width || 280,
                },
            }}
        >
            <DialogTitle id="alert-dialog-title" style={{ overflow: 'visible' }}>
                {ico && (
                    <div style={{ color, justifyContent: 'center', display: 'flex' }}>
                        <Icon
                            style={{ marginTop: -40, overflow: 'visible' }}
                            icon={ico}
                            width={80}
                            height={80}
                        />
                    </div>
                )}
            </DialogTitle>
            <DialogContent>
                <div style={{ color: 'white', textAlign: 'center' }}>
                    <DialogContentText id="alert-dialog-description">{children}</DialogContentText>
                </div>
            </DialogContent>
            <DialogActions style={{ padding: 10, justifyContent: 'center' }}>
                <Button
                    disabled={disabledButton1}
                    onClick={handleButton}
                    variant="contained"
                    autoFocus
                >
                    {buttonText}
                </Button>
                {buttonText2 && (
                    <Button
                        disabled={disabledButton2}
                        onClick={handleButton2}
                        variant="contained"
                        autoFocus
                    >
                        {buttonText2}
                    </Button>
                )}
                {buttonText3 && (
                    <Button
                        disabled={disabledButton3}
                        onClick={handleButton3}
                        variant="contained"
                        autoFocus
                    >
                        {buttonText3}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};
