/*
  Imports
*/
import { Container } from '@mui/material';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Page from '../components/Page';
import ListPageTitle from 'src/components/misc/ListPageTitle';
import { Box } from '@mui/system';
import userService from 'src/services/UserServiceClass';
import CenterLoading from 'src/components/misc/CenterLoading';
import SecurityForm from './forms/SecurityForm';
/*
  Main Working
*/
export default () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState();

    const title = `Security`;

    const getData = () => {
        setLoading(true);
        userService
            .getMe()
            .then((data) => {
                setUser(data);
            })
            .catch((err) => {
                if (err.response) navigate('/logout');
                console.error(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(getData, []);

    return (
        <Page title={title}>
            {loading || !user ? (
                <CenterLoading />
            ) : (
                <Container>
                    <ListPageTitle>{title}</ListPageTitle>
                    <Box sx={{ width: '80%' }}>
                        <SecurityForm user={user} />
                    </Box>
                </Container>
            )}
        </Page>
    );
};
