import { createContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
//
import Auth from 'src/components/authentication/login/Auth';
import { Route401, RouteLogin } from 'src/config/routes';
import Footer from './Footer';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import ResponsiveFooter from './ResponsiveFooter';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
    display: 'flex',
    minHeight: '100%',
    overflow: 'hidden',
});

const MainStyle = styled('div')(({ theme }) => ({
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100%',
    paddingTop: APP_BAR_MOBILE + 24,
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up('lg')]: {
        paddingTop: APP_BAR_DESKTOP + 24,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
}));

export const DashboardContext = createContext();

export default function DashboardLayout({ rank }) {
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();

    const [reload, setReload] = useState(false);
    const reloadApp = () => {
        setReload(true);
        setTimeout(() => {
            setReload(false);
        }, 1000);
    };

    const [open, setOpen] = useState(false);

    const [loggedIn, setLoggedIn] = useState(false);

    const handleOnAuth = (pass, e401) => {
        if (e401) {
            alert('Not allowed here!');
            return navigate(Route401, { state: { from: location }, replace: true });
        }

        if (pass) return setLoggedIn(true);

        //if (rank)
        return navigate(RouteLogin, { state: { from: location }, replace: true });
    };

    useEffect(() => {
        if (searchParams.get('game-set')) {
            localStorage.setItem('selectedGame', searchParams.get('game-set'));
            searchParams.delete('game-set');
            setSearchParams(searchParams);
        }
    }, []);

    return (
        <DashboardContext.Provider value={{ reload: reloadApp }}>
            <Auth rank={rank} onAuth={handleOnAuth}>
                {loggedIn && (
                    <RootStyle>
                        {!reload && <DashboardNavbar onOpenSidebar={() => setOpen(true)} />}
                        <DashboardSidebar
                            isOpenSidebar={open}
                            onCloseSidebar={() => setOpen(false)}
                        />
                        {/* <Box
                            sx={{
                                display: 'flex',
                                width: '78%',
                                flexDirection: 'column',
                            }}
                        > */}
                        <MainStyle>
                            <Outlet />
                        </MainStyle>
                        {/* </Box> */}
                    </RootStyle>
                )}
                {/* <Footer /> */}
                <ResponsiveFooter />
            </Auth>
        </DashboardContext.Provider>
    );
}
