/*
  Imports
*/
import { Container, Typography, Grid, Link } from '@mui/material';
import { PageInProgress } from 'src/theme/logo-only-pages';
import Hammer from 'src/components/Hammer';
import { SETTINGS } from 'src/config/settings';

/*
  Main Working
*/

export default function WorkInProgress() {
    let image = SETTINGS.HammerBackgroundImage;
    return (
        <Container>
            <div
                style={{
                    backgroundImage: `url(${image})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                    backgroundPosition: 'center right',
                }}
            >
                <Grid container spacing={0} direction="column" style={{ minHeight: '100vh' }}>
                    <Grid item xs={3}>
                        <PageInProgress>
                            <Grid
                                container
                                direction="row"
                                alignItems="center"
                                justify="center"
                                sx={{ mt: 3, mb: 5 }}
                                spacing={1}
                            >
                                <Grid item md={2} sm={2} xs={2}>
                                    <Hammer />
                                </Grid>
                                <Grid item md={10} sm={10} xs={10} sx={{ marginBottom: -1.5 }}>
                                    <Typography variant="h3" gutterBottom sx={{ color: 'black' }}>
                                        COME BACK LATER!
                                    </Typography>
                                </Grid>
                                <Grid item sx={{ textAlign: 'center', mt: 3 }}>
                                    <Typography variant="h4" gutterBottom sx={{ color: 'black' }}>
                                        WE ARE CAREFULLY <br /> CRAFTING THIS WEBSITE SECTION FOR
                                        YOU.
                                    </Typography>
                                </Grid>
                                <Grid item md={0.5} sm={0.5} xs={0.5} sx={{ mt: 3 }}></Grid>
                                <Grid
                                    item
                                    md={11}
                                    sm={11}
                                    xs={11}
                                    sx={{ textAlign: 'center', mt: 3 }}
                                >
                                    <Typography variant="h4" gutterBottom sx={{ color: 'black' }}>
                                        JOIN{' '}
                                        <Link
                                            href="https://discord.com/invite/nWt9Nd8B6z"
                                            target="_blank"
                                        >
                                            DISCORD
                                        </Link>{' '}
                                        <br /> AND BE UP TO DATE!
                                    </Typography>
                                </Grid>
                            </Grid>
                        </PageInProgress>
                    </Grid>
                </Grid>
            </div>
        </Container>
    );
}
