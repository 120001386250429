import GenericService from './GenericService';
import qs from 'qs';
import axios from 'axios';
import { SETTINGS } from 'src/config/settings';
const SHOP_BASE_URL = 'https://store.xsolla.com/api/v2/project/';

class CheckoutService extends GenericService {

    constructor() {
        super();
        this.shopService = axios.create({
            baseURL: SHOP_BASE_URL,
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    checkout = (data) => {

        return new Promise((resolve, reject) => {

            const { game, isFree, item, quantity } = data;

            const projectId = SETTINGS.GAMES[game].projectId;
            this.shopService.post(`/${projectId}/${isFree ? 'free' : 'payment'}/item/${item}`, {
                sandbox: SETTINGS.SANDBOX,
                quantity,
            },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('xtoken')}`
                    }
                }
            ).then((response) => {
                let url = 'https://secure.xsolla.com/paystation2/?access_token=';
                if (SETTINGS.SANDBOX)
                    url = 'https://sandbox-secure.xsolla.com/paystation2/?access_token=';

                url += response.data.token;
                resolve({ url, id: response.data.order_id });
            }).catch((error) => {
                reject({
                    error: true,
                    msg: error.response?.data?.errorMessage ?? "Something went wrong"
                })
            });
        });

    };

    getMyTransactions = (pagination) => {
        this.tokenUpdate();
        return this.get(`/shop/me/transactions?${qs.stringify(pagination)}`);
    };

    // createOrder = (game, server, id) => {
    //     this.tokenUpdate();
    //     return this.post(`/shop/order?game=${game}&server=${server}`, { id });
    // }
}

const checkoutService = new CheckoutService();
export default checkoutService;
