import GenericService from './GenericService';
import qs from 'qs';

class RankingsService extends GenericService {
    getRankings = (params) => {
        this.tokenUpdate();
        return this.get(`/rankings?${qs.stringify(params)}`);
    };

    getSummary = (id, params) => {
        this.tokenUpdate();
        return this.get(`/rankings/${id}?${qs.stringify(params)}`);
    };
}

const rankingsService = new RankingsService();
export default rankingsService;
