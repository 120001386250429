import { Navigate, useRoutes } from 'react-router-dom';
import DashboardLayout from './layouts/DashboardLayout';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import LogoOnlyAuthLayout from './layouts/LogoOnlyAuthLayout';
import VersionCheckWrapper from './components/VersionCheckWrapper';

import {
    RouteDefaultPage,
    RouteForgetPass,
    RouteLandingPage,
    RouteResetPass,
} from './config/routes';

import ForgetPassword from './pages/ForgetPassword';
import Login from './pages/Login';
import Logout from './pages/Logout';
import PageError from './pages/PageError';
import ResetPassword from './pages/ResetPassword';
import Accounts from './pages/Accounts';
import GemsStorePage from './pages/GemsStorePage';
import Migration from './pages/Migration';
import RedeemCode from './pages/RedeemCode';
import Register from './pages/Register';
import Security from './pages/Security';
import Transactions from './pages/Transactions';
import LoyaltyRewards from './pages/LoyaltyRewards';
import Rankings from './pages/Rankings';
import CharacterOptimization from './pages/CharacterOptimization';

export default function Router() {
    return (
        <VersionCheckWrapper>
            {useRoutes([
                {
                    path: '/login',
                    element: <LogoOnlyAuthLayout loginPage />,
                    children: [{ path: '', element: <Login teacher /> }],
                },
                {
                    path: 'accounts',
                    element: <DashboardLayout />,
                    children: [{ path: '', element: <Accounts /> }],
                },
                {
                    path: 'migration',
                    element: <DashboardLayout />,
                    children: [{ path: '', element: <Migration /> }],
                },
                {
                    path: 'gems',
                    element: <DashboardLayout />,
                    children: [{ path: '', element: <GemsStorePage /> }],
                },
                {
                    path: 'transactions',
                    element: <DashboardLayout />,
                    children: [{ path: '', element: <Transactions /> }],
                },
                {
                    path: 'rankings',
                    element: <DashboardLayout />,
                    children: [{ path: '', element: <Rankings /> }],
                },
                {
                    path: 'security',
                    element: <DashboardLayout />,
                    children: [{ path: '', element: <Security /> }],
                },
                {
                    path: 'redeemcode',
                    element: <DashboardLayout />,
                    children: [{ path: '', element: <RedeemCode /> }],
                },
                {
                    path: 'characteroptimization',
                    element: <DashboardLayout />,
                    children: [{ path: '', element: <CharacterOptimization /> }],
                },
                {
                    path: 'loyalty-cards',
                    element: <DashboardLayout />,
                    children: [{ path: '', element: <LoyaltyRewards /> }],
                },
                {
                    path: RouteLandingPage,
                    element: <Navigate to={RouteDefaultPage} replace />,
                },
                {
                    path: RouteLandingPage,
                    element: <LogoOnlyLayout />,
                    children: [
                        { path: 'logout', element: <Logout /> },
                        { path: 'register', element: <Register /> },
                        { path: '404', element: <PageError e404 /> },
                        { path: '401', element: <PageError /> },
                        { path: 'reset.html', element: <ResetPassword /> },
                        { path: RouteForgetPass, element: <ForgetPassword /> },
                        { path: RouteResetPass, element: <ResetPassword /> },
                    ],
                },
                { path: '*', element: <Navigate to="/404" replace /> },
            ])}
        </VersionCheckWrapper>
    );
}