import { Repeat } from 'src/config/icons';
import { Container, Grid, Typography, Chip, Box } from '@mui/material';
import { Icon } from '@iconify/react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CenterLoading from 'src/components/misc/CenterLoading';
import ListPageTitle from 'src/components/misc/ListPageTitle';
import CustomStepper from 'src/components/misc/timelines/CustomStepper';
import Page from 'src/components/Page';
import userService from 'src/services/UserServiceClass';

export default function HorizontalLinearStepper() {
    // const { gameId } = useParams();

    const gameId = localStorage.getItem('selectedGame') ?? 1;

    const [loading, setLoading] = useState(true);

    const [rewards, setRewards] = useState([]);

    const [time, setTime] = useState([]);

    let counterArray = [];

    const title = `Loyalty Rewards`;

    const getData = () => {
        setLoading(true);
        userService
            .getRewards(gameId)
            .then((data) => {
                setRewards(data);
                data.forEach((reward) => {
                    startCounter(reward?.id, reward?.end);
                });
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const startCounter = async (id, endDate) => {
        const eDate = new Date(endDate).getTime();
        calculateCounter(id, eDate);
        setInterval(() => {
            calculateCounter(id, eDate);
        }, 60000);
    };

    const calculateCounter = (id, eDate) => {
        const now = new Date().getTime();
        const counter = eDate - now;
        const days = Math.floor(counter / (1000 * 60 * 60 * 24));
        const hours = Math.floor((counter % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((counter % (1000 * 60 * 60)) / (1000 * 60));

        let timeText = '';
        if (days > 0) {
            timeText = `Time Left: ${timeToShow(days)}d ${timeToShow(
                hours,
            )}h ${timeToShow(minutes)}m`;
        } else if (hours > 0) {
            timeText = `Time Left: ${timeToShow(hours)}h ${timeToShow(minutes)}m`;
        } else if (minutes > 0) {
            timeText = `Time Left: ${timeToShow(minutes)}m`;
        } else {
            timeText = `Time Over`;
        }

        counterArray = counterArray.filter((t) => t.id != id);
        counterArray.push({
            id,
            time: timeText,
        });
        setTime(counterArray);
    };

    const timeToShow = (time) => {
        return time < 10 ? `0` + time : time;
    };

    useEffect(getData, []);

    return (
        <Page title={title}>
            {loading || !rewards ? (
                <CenterLoading />
            ) : (
                <Container>
                    <ListPageTitle mb={5}>{title}</ListPageTitle>
                    <Grid container>
                        {rewards?.map((reward) => (
                            <>
                                <div
                                    style={{
                                        background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),url(${reward.bg_image})`,
                                        minWidth: '100%',
                                        padding: '2em 1em',
                                    }}
                                >
                                    <Grid container justifyContent="space-between" margin="2px">
                                        <Typography marginBottom={2}>
                                            <span style={{ fontWeight: 'bold', fontSize: '1.1em' }}>
                                                {reward.title}{' '}
                                            </span>
                                            <img
                                                src="/static/illustrations/gem.png"
                                                height={15}
                                                style={{ display: 'inline' }}
                                            />
                                            <span style={{ fontSize: '0.9em' }}>
                                                {' '}
                                                {reward.title_2}{' '}
                                            </span>
                                            <br />
                                            <span style={{ fontSize: '0.8em' }}>
                                                {reward.subtitle}
                                            </span>
                                        </Typography>

                                        <span>
                                            {/* {reward.start.slice(0, 10)} <br /> */}
                                            {/* {new Date(reward.start).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })} */}
                                            {time?.filter((t) => t.id === reward.id)[0]?.time}
                                        </span>
                                    </Grid>
                                    <CustomStepper
                                        steps={reward.points}
                                        getData={getData}
                                        game={gameId}
                                    />

                                    {reward.repeatable ? (
                                        <Grid
                                            container
                                            justifyContent="flex-end"
                                            alignItems="flex-end"
                                            marginTop="2em"
                                        >
                                            <Grid item>
                                                {/* Use Box component for the chip with sharp white glow effect and red background */}

                                                <Chip
                                                    label="Repeatable"
                                                    variant="filled"
                                                    color="warning"
                                                    avatar={<Icon icon={Repeat} color="black" />}
                                                    sx={{
                                                        outline: '2px solid black',
                                                        textShadow:
                                                            '0px 0px 8px rgba(255, 255, 255, 1)',
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                                <div style={{ height: '3em', width: '100%' }}> </div>
                            </>
                        ))}
                    </Grid>
                    <br />
                    <Chip
                        label="Repeatable"
                        variant="filled"
                        color="warning"
                        avatar={<Icon icon={Repeat} color="black" />}
                        sx={{
                            outline: '2px solid black',
                            textShadow:
                                '0px 0px 8px rgba(255, 255, 255, 1)',
                        }}
                    /> You need to claim the last reward. Once claimed, the loyalty campaign will start from 0.
                </Container>
            )}
        </Page>
    );
}
