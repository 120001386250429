import { Box, Card, CardHeader } from '@mui/material';
import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
import BaseOptionChart from './BaseOptionChart';

export default ({ labels, data, type, title, percent }) => {
    const chartOptions = merge(BaseOptionChart(), {
        stroke: { curve: 'smooth', width: 2 },
        plotOptions: { bar: { columnWidth: '10%', borderRadius: 1 } },
        fill: { type: type ?? ['gradient', 'gradient', 'solid'] },

        markers: {
            size: 7,
            hover: {
                size: undefined,
                sizeOffset: 3,
            },
        },
        labels,
        tooltip: {
            shared: true,
            intersect: false,
            y: {
                formatter: (y) => {
                    return y.toFixed(0) + (percent ? '%' : '');
                },
            },
        },
        yaxis: {
            labels: {
                formatter: (value) => {
                    return value;
                },
            },
        },
    });

    return (
        <Box sx={{ p: 3, pb: 1 }} dir="ltr">
            <ReactApexChart
                type="bar"
                labels={labels}
                series={data}
                options={chartOptions}
                width="100%"
                height={200}
            />
        </Box>
    );
};
