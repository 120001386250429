import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import { Container, Typography, Grid, Link } from '@mui/material';
import moment from 'moment';
import 'moment/locale/en-gb';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    countdown: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },
    },
    digit: {
        fontSize: '4rem',
        fontWeight: 'bold',
        margin: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            fontSize: '3rem',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '3rem',
        },
    },
    label: {
        fontSize: '1.5rem',
        textTransform: 'uppercase',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '1rem',
        },
    },
}));

export default function WeeklyCountdown() {
    const classes = useStyles();
    const theme = useTheme();
    const [remainingTime, setRemainingTime] = useState(0);

    useEffect(() => {
        const calculateRemainingTime = () => {
            const now = moment.utc(); // Get the current UTC time
            const endOfWeek = moment.utc().endOf('week'); // Get the end of the week in UTC time
            const duration = moment.duration(endOfWeek.diff(now)); // Calculate the duration between now and end of week

            // Convert the duration to milliseconds
            const timeLeft = duration.asMilliseconds();

            setRemainingTime(timeLeft);
        };

        calculateRemainingTime(); // Initial calculation

        const timer = setInterval(calculateRemainingTime, 60000);

        return () => {
            clearInterval(timer);
        };
    }, []);

    const formatTime = (time) => {
        const duration = moment.duration(time);
        const days = Math.floor(duration.asDays());
        const hours = duration.hours();
        const minutes = duration.minutes();

        return { days, hours, minutes };
    };

    const renderCountdown = () => {
        const { days, hours, minutes } = formatTime(remainingTime);

        return (
            <div className={classes.countdown}>
                <Typography variant="h2" className={classes.digit}>
                    {days.toString().padStart(2, '0')}
                </Typography>
                <Typography variant="body1" className={classes.label}>
                    Days
                </Typography>
                <Typography variant="h2" className={classes.digit}>
                    {hours.toString().padStart(2, '0')}
                </Typography>
                <Typography variant="body1" className={classes.label}>
                    Hours
                </Typography>
                <Typography variant="h2" className={classes.digit}>
                    {minutes.toString().padStart(2, '0')}
                </Typography>
                <Typography variant="body1" className={classes.label}>
                    Minutes
                </Typography>
            </div>
        );
    };

    return <div className={classes.root}>{renderCountdown()}</div>;
}
