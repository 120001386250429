import React, { useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Grid } from '@mui/material';
import { SETTINGS } from 'src/config/settings';

export default function FilterComponent({
    selectedFaction,
    setSelectedFaction,
    selectedClass,
    setSelectedClass,
    getData,
}) {
    const handleFactionChange = (event) => {
        console.log('Event', event);
        setSelectedFaction(event.target.value);
        setSelectedClass(''); // Reset the selected class when faction changes
        // getData();
    };

    const handleClassChange = (event) => {
        const selectedClass = event.target.value;
        setSelectedClass(selectedClass);
        // getData();
    };

    const selectedFactionData = SETTINGS.FILTERS.find((filter) => filter.id === selectedFaction);

    const classOptions = selectedFactionData ? selectedFactionData.Class : [];

    return (
        <Grid container>
            <Grid item xs={12} md={6}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} sx={{ marginBottom: 3 }}>
                        <FormControl fullWidth>
                            <InputLabel>Faction</InputLabel>
                            <Select
                                value={selectedFaction}
                                onChange={handleFactionChange}
                                label="Faction"
                            >
                                <MenuItem value={''}>All</MenuItem>
                                {SETTINGS.FILTERS.map((filter) => (
                                    <MenuItem key={filter.id} value={filter.id}>
                                        {filter.Server}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    {selectedFaction && (
                        <Grid item xs={12} md={6} sx={{ marginBottom: 3 }}>
                            <FormControl fullWidth>
                                <InputLabel>Class</InputLabel>
                                <Select
                                    value={selectedClass}
                                    onChange={handleClassChange}
                                    label="Class"
                                >
                                    <MenuItem value={''}>All</MenuItem>
                                    {classOptions.map((classOption, index) => (
                                        <MenuItem key={classOption.label} value={index}>
                                            {classOption.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
}
