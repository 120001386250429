import PropTypes from 'prop-types';
// material
import { Box } from '@mui/material';
import { SETTINGS } from 'src/config/settings';
import { Link } from 'react-router-dom';

// ----------------------------------------------------------------------

Hammer.propTypes = {
    sx: PropTypes.object,
};

export default function Hammer({ sx, path = SETTINGS.HammerImage }) {
    return (
        <Box margin="auto" component="img" src={path} sx={{ width: 180, height: 'auto', ...sx }} />
    );
}
