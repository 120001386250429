import GenericService from './GenericService';
import qs from 'qs';

class GeneralService extends GenericService {
    getServers = () => {
        return this.get(`/game/servers`);
    };
}

const generalService = new GeneralService();
export default generalService;
