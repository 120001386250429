/*
  Imports
*/
import LogoOnlyBody from 'src/components/misc/LogoOnlyBody';
import { SETTINGS } from 'src/config/settings';
import { RootStyle } from 'src/theme/logo-only-pages';
import LoginForm from './forms/LoginForm';

/*
  Main Working
*/
export default ({ }) => {
    const loginDisplay = 'User';
    const image = SETTINGS.UserLoginImage;

    return (
        <RootStyle title={loginDisplay}>
            <LogoOnlyBody image={image} title={"Login"}>
                {/* <Stack sx={{ mb: 6 }}>
                    
                    <Typography sx={{ color: palette.text.secondary }}>
                        Enter Your Login Details Below.
                    </Typography>
                </Stack> */}
                <LoginForm />
            </LogoOnlyBody>
        </RootStyle>
    );
};
