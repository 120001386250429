/*
    Imports
*/
import { Stack, Typography } from '@mui/material';
import LogoOnlyBody from 'src/components/misc/LogoOnlyBody';
import { SETTINGS } from 'src/config/settings';
import { RootStyle } from 'src/theme/logo-only-pages';
import palette from 'src/theme/palette';
import ResetPasswordForm from './forms/ResetPasswordForm';

/*
    Main Working
*/
export default () => {
    return (
        <RootStyle title="Reset Password">
            <LogoOnlyBody image={SETTINGS.ForgetPageImage} title="Password Reset">
                <ResetPasswordForm />

            </LogoOnlyBody>
        </RootStyle>
    );
};
