/*
  Imports
*/
import { Stack, Typography } from '@mui/material';
import LogoOnlyBody from 'src/components/misc/LogoOnlyBody';
import { SETTINGS } from 'src/config/settings';
import { RootStyle } from 'src/theme/logo-only-pages';
import palette from 'src/theme/palette';
import RegisterForm from './forms/RegisterForm';

/*
  Main Working
*/
export default ({ }) => {
    const loginDisplay = 'User';
    const image = SETTINGS.UserLoginImage;

    return (
        <RootStyle title={loginDisplay}>
            <LogoOnlyBody image={image} title="Create Account">
                <RegisterForm />
            </LogoOnlyBody>
        </RootStyle>
    );
};
