import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import { Icon } from '@iconify/react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { matchPath, NavLink as RouterLink, useLocation } from 'react-router-dom';
// material
import { Box, Collapse, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { alpha, styled, useTheme } from '@mui/material/styles';

// ----------------------------------------------------------------------

const ListItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)(
    ({ theme }) => ({
        ...theme.typography.body2,
        height: 48,
        position: 'relative',
        textTransform: 'capitalize',
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(2.5),
        color: theme.palette.text.secondary,
        '&:before': {
            top: 0,
            right: 0,
            width: 3,
            bottom: 0,
            content: "''",
            display: 'none',
            position: 'absolute',
            borderTopLeftRadius: 4,
            borderBottomLeftRadius: 4,
            backgroundColor: theme.palette.primary.main,
        },
    }),
);

const ListItemIconStyle = styled(ListItemIcon)({
    width: 22,
    height: 22,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
    item: PropTypes.object,
    active: PropTypes.func,
};

function NavItem({ onClick, item, active }) {
    const theme = useTheme();
    const isActiveRoot = active(item.path);
    const { title, path, icon, info, children, outside } = item;
    const [open, setOpen] = useState(isActiveRoot);
    const selectedGame = localStorage.getItem('selectedGame') ?? 1;

    const handleOpen = () => {
        setOpen((prev) => !prev);
    };

    const activeRootStyle = {
        color: 'primary.main',
        fontWeight: 'fontWeightMedium',
        bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
        '&:before': { display: 'block' },
    };

    // const activeSubStyle = {
    //     color: 'text.primary',
    //     fontWeight: 'fontWeightMedium',
    // };

    let component = RouterLink;
    if (outside) {
        component = 'a';
    }

    if (children) {
        return (
            <>
                <ListItemStyle
                    onClick={handleOpen}
                    sx={{
                        ...(isActiveRoot && activeRootStyle),
                    }}
                >
                    <ListItemIconStyle>{icon}</ListItemIconStyle>
                    <ListItemText disableTypography primary={title} />
                    {info}
                    <Box
                        component={Icon}
                        icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
                        sx={{ width: 16, height: 16, ml: 1 }}
                    />
                </ListItemStyle>

                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {children
                            .filter((i) => {
                                if (Array.isArray(i.game) && i.game.length > 0)
                                    return i.game.includes(+selectedGame);
                                return i.game === undefined || i.game === +selectedGame;
                            })
                            .map((citem) => {
                                const {
                                    title: c_title,
                                    path: c_path,
                                    outside: c_outside,
                                    icon: c_icon,
                                } = citem;
                                const isActiveSub = active(c_path);

                                let c_component = RouterLink;
                                if (c_outside) {
                                    c_component = 'a';
                                }

                                return (
                                    <ListItemStyle
                                        key={c_title}
                                        component={c_component}
                                        to={c_path}
                                        href={c_path}
                                        {...(c_component == 'a' && {
                                            target: '_blank',
                                            rel: 'noreferrer',
                                        })}
                                        onClick={onClick}
                                        sx={{
                                            ...(isActiveSub && activeRootStyle),
                                            marginLeft: theme.spacing(2),
                                        }}
                                    >
                                        <ListItemIconStyle>
                                            {/* <Box
                                            component="span"
                                            sx={{
                                                width: 4,
                                                height: 4,
                                                display: 'flex',
                                                borderRadius: '50%',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                bgcolor: 'text.disabled',
                                                transition: (t) =>
                                                    t.transitions.create('transform'),
                                                ...(isActiveSub && {
                                                    transform: 'scale(2)',
                                                    bgcolor: 'primary.main',
                                                }),
                                            }}
                                        /> */}{' '}
                                            {c_icon}
                                        </ListItemIconStyle>
                                        <ListItemText disableTypography primary={c_title} />
                                    </ListItemStyle>
                                );
                            })}
                    </List>
                </Collapse>
            </>
        );
    }

    return (
        <ListItemStyle
            component={component}
            to={path}
            href={path}
            {...(component == 'a' && {
                target: '_blank',
                rel: 'noreferrer',
            })}
            onClick={onClick}
            sx={{
                ...(isActiveRoot && activeRootStyle),
            }}
        >
            <ListItemIconStyle>{icon}</ListItemIconStyle>
            <ListItemText disableTypography primary={title} />
            {info}
        </ListItemStyle>
    );
}

NavSection.propTypes = {
    navConfig: PropTypes.array,
};

export default function NavSection({ navConfig, ...other }) {
    const { pathname } = useLocation();
    const selectedGame = localStorage.getItem('selectedGame') ?? 1;

    const match = (path) => (path ? !!matchPath({ path, end: false }, pathname) : false);

    return (
        <Box {...other}>
            <List disablePadding>
                {navConfig
                    .filter((i) => {
                        if (Array.isArray(i.game) && i.game.length > 0)
                            return i.game.includes(+selectedGame);
                        return i.game === undefined || i.game === +selectedGame;
                    })
                    .map((item) => (
                        <NavItem key={item.title} item={item} active={match} />
                    ))}
            </List>
        </Box>
    );
}
