import PropTypes from 'prop-types';
// material
import { Box } from '@mui/material';
import { SETTINGS } from 'src/config/settings';

// ----------------------------------------------------------------------

Logo.propTypes = {
    sx: PropTypes.object,
};

export default function Logo({ sx }) {
    return (
        <Box
            margin="auto"
            component="img"
            src={SETTINGS.LogoImage}
            sx={{ width: '32px', height: '32px', ...sx }}
        />
    );
}
