/*
    Imports
*/
import { Icon } from '@iconify/react';
import { Alert, Box, Collapse, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Dialog from 'src/components/misc/alerts/Dialog';
import LoadingFormButton from 'src/components/misc/Buttons/LoadingFormButton';
import { ResetSchema } from 'src/config/form-schemas';
import { ErrorDialogIcon, HidePasswordIcon, PasswordIcon, ShowPasswordIcon, SuccessDialogIcon } from 'src/config/icons';
import { asciiRegExp } from 'src/config/regex';
import { RouteLandingPage } from 'src/config/routes';
import palette from 'src/theme/palette';
import userService from '../../services/UserServiceClass';

/*
    Main Working
*/
export default () => {
    /*
        States, Params, Navigation, Query.
    */
    const [searchParams, _setSearchParams] = useSearchParams();
    const [password, setPassword] = useState('');
    const [serverError, setServerError] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [openDia, setOpenDia] = useState(false);
    const [showPasswordError, setShowPasswordError] = useState(false);

    const navigate = useNavigate();
    const code = searchParams.get('code');
    const id = searchParams.get('user_id');

    /*
        Form Setup
    */
    const formik = useFormik({
        initialValues: {
            password: '',
            confirm: '',
            code,
            id,
        },
        validationSchema: ResetSchema(password),
        onSubmit: (values, { setSubmitting, setFieldError }) => {
            setServerError('');
            userService
                .resetPassword({ id: values.id, password: values.password, code: values.code })
                .then(() => {
                    setOpenDia(true);
                })
                .catch((err) => {
                    console.error('Error', err);
                    setSubmitting(false);
                    if (!err.response) {
                        setServerError('Error occurred please try later');
                    } else {
                        setServerError(err.response.data.msg);
                    }
                });
        },
    });
    const { values, errors, touched, isSubmitting, handleSubmit, getFieldProps, resetForm } =
        formik;

    /*
        Handlers
    */

    const handleClose = () => {
        resetForm();
        setOpenDia(false);
        navigate(RouteLandingPage);
    };

    const handleShowPassword = () => {
        setShowPassword((show) => !show);
    };

    const handlePasswordUpdate = () => {
        setPassword(values.password);
    };

    /*
        Use Effect Hooks.
    */

    useEffect(handlePasswordUpdate, [values.password]);

    const pass_length_e = values.password.length < 8;
    const pass_mlength_e = values.password.length > 16;
    const pass_ascii_e = !/^[a-zA-Z0-9#!\*%\-_=]+$/.test(values.password);
    const pass_upper_e = !/[A-Z]/.test(values.password);
    const pass_lower_e = !/[a-z]/.test(values.password);
    const pass_number_e = !/[0-9]/.test(values.password);
    const pass_special_e = !/[#!\*%\-_=]/.test(values.password);
    const pass_space_e = !/^[^\s]*$/.test(values.password);

    /*
        Main Design
    */
    return (
        <FormikProvider value={formik}>
            <Form noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                    <TextField
                        fullWidth
                        type={showPassword ? 'text' : 'password'}
                        label="New Password"
                        {...getFieldProps('password')}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleShowPassword} edge="end">
                                        <Icon
                                            icon={
                                                showPassword ? HidePasswordIcon : ShowPasswordIcon
                                            }
                                        />
                                    </IconButton>
                                </InputAdornment>
                            ),
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Icon
                                        icon={PasswordIcon}
                                        inline="true"
                                        style={{ fontSize: 20 }}
                                    />
                                </InputAdornment>
                            ),
                        }}
                        error={Boolean(touched.password && errors.password)}
                        // helperText={touched.password && errors.password}
                        onFocus={() => setShowPasswordError(true)}
                    />
                    {(showPasswordError) &&
                        <Box style={{ marginTop: 0, marginBottom: 0 }} sx={{
                            p: 1, pl: 2,
                            bgcolor: palette.background.default,
                            // border: 2,
                            // borderColor: "primary.main",
                        }} >

                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                <Icon
                                    icon={pass_length_e ? ErrorDialogIcon : SuccessDialogIcon}
                                    inline="true"
                                    style={{
                                        fontSize: 14,
                                        color: pass_length_e ? palette.error.main : palette.success.main
                                    }}
                                ></Icon> More than 8 characters. <br />
                                <Icon
                                    icon={pass_mlength_e ? ErrorDialogIcon : SuccessDialogIcon}
                                    inline="true"
                                    style={{
                                        fontSize: 14,
                                        color: pass_mlength_e ? palette.error.main : palette.success.main
                                    }}
                                ></Icon> Less than 16 characters. <br />
                                <Icon
                                    icon={pass_upper_e ? ErrorDialogIcon : SuccessDialogIcon}
                                    inline="true"
                                    style={{
                                        fontSize: 14,
                                        color: pass_upper_e ? palette.error.main : palette.success.main
                                    }}
                                ></Icon> At least one uppercase letter. <br />
                                <Icon
                                    icon={pass_lower_e ? ErrorDialogIcon : SuccessDialogIcon}
                                    inline="true"
                                    style={{
                                        fontSize: 14,
                                        color: pass_lower_e ? palette.error.main : palette.success.main
                                    }}
                                ></Icon> At least one lowercase letter. <br />
                                <Icon
                                    icon={pass_number_e ? ErrorDialogIcon : SuccessDialogIcon}
                                    inline="true"
                                    style={{
                                        fontSize: 14,
                                        color: pass_number_e ? palette.error.main : palette.success.main
                                    }}
                                ></Icon> At least one number (0-9). <br />
                                <Icon
                                    icon={pass_special_e ? ErrorDialogIcon : SuccessDialogIcon}
                                    inline="true"
                                    style={{
                                        fontSize: 14,
                                        color: pass_special_e ? palette.error.main : palette.success.main
                                    }}
                                ></Icon> At least 1 special character from: #, !, *, %, -, _ or = <br />
                                <Icon
                                    icon={pass_ascii_e ? ErrorDialogIcon : SuccessDialogIcon}
                                    inline="true"
                                    style={{
                                        fontSize: 14,
                                        color: pass_ascii_e ? palette.error.main : palette.success.main
                                    }}
                                ></Icon> Can't contain special letters (ü, Æ, ñ, ß, Ø, etc.) <br />
                                <Icon
                                    icon={pass_space_e ? ErrorDialogIcon : SuccessDialogIcon}
                                    inline="true"
                                    style={{
                                        fontSize: 14,
                                        color: pass_space_e ? palette.error.main : palette.success.main
                                    }}
                                ></Icon> Can't contain spaces. <br />
                            </Typography>
                        </Box>
                    }
                    <br />
                    <TextField
                        fullWidth
                        type={showPassword ? 'text' : 'password'}
                        label="Confirm Password"
                        {...getFieldProps('confirm')}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleShowPassword} edge="end">
                                        <Icon
                                            icon={
                                                showPassword ? HidePasswordIcon : ShowPasswordIcon
                                            }
                                        />
                                    </IconButton>
                                </InputAdornment>
                            ),
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Icon
                                        icon={PasswordIcon}
                                        inline="true"
                                        style={{ fontSize: 20 }}
                                    />
                                </InputAdornment>
                            ),
                        }}
                        error={Boolean(touched.confirm && errors.confirm)}
                        helperText={touched.confirm && errors.confirm}
                    />

                    <br />
                    {/* <TextField
                        fullWidth
                        disabled
                        label="Reset Code"
                        {...getFieldProps('code')}
                        InputProps={{
                            startAdornment: <InputAdornment position="start"></InputAdornment>,
                        }}
                        error={Boolean(touched.code && errors.code)}
                        helperText={touched.code && errors.code}
                    /> */}
                </Stack>

                <br />
                <br />

                <Dialog buttonText={'Close'} openDialog={openDia} handleButton={handleClose}>
                    Password changed successfully, you may login with new password now.
                </Dialog>

                <LoadingFormButton
                    style={{ padding: '0px 70px' }}
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                >
                    Reset
                </LoadingFormButton>

                {serverError && (
                    <Stack sx={{}} marginTop={3}>
                        <Collapse in={!!serverError}>
                            <Alert severity="error">{serverError}</Alert>
                        </Collapse>
                    </Stack>
                )}
            </Form>
        </FormikProvider>
    );
};
