import { Box, Grid, Stack, Typography, IconButton, Link, SvgIcon } from '@mui/material';
import FooterLogo from 'src/components/FooterIcons';
import { MHidden } from '../components/@material-extend';
import {
    RouteAboutUs,
    RouteCompnay,
    RouteCookies,
    RouteDiscord,
    RouteFacebook,
    RouteHome,
    RouteLinkedIn,
    RoutePrivacy,
    RouteSupport,
    RouteTerms,
} from 'src/config/routes';
import { SETTINGS } from 'src/config/settings';
import { Icon } from '@iconify/react';
import { MailIcon } from 'src/config/icons';

export default function ResponsiveFooter(props) {
    const subTextStyle = {
        color: 'text.primary',
        fontFamily: 'Raleway, sans-serif',
        fontWeight: 400,
        fontSize: '0.875rem',

        '&:hover': {
            color: 'primary.main',
            fontFamily: 'Raleway, sans-serif',
        },
    };

    const textStyle = {
        color: 'text.primary',
        fontFamily: 'Raleway, sans-serif',
        fontSize: '1rem',
        fontWeight: 700,
        lineHeight: '25.6px',
    };

    return (
        <>
            <Box
                sx={{
                    borderTop: 0.5,
                    borderColor: '#232629',
                    position: 'absolute',
                    width: '100%',
                    height: '204px',
                    zIndex: { xs: 1000, md: 1400 },
                    backgroundColor: 'black',
                    display: 'flex',
                }}
            >
                <MHidden width="lgDown">
                    <Grid
                        container
                        sx={{
                            marginLeft: { md: '13%', lg: '13%' },
                            marginTop: 4,
                            // borderTop: 0.5,
                            // borderColor: '#240E0C',
                        }}
                    >
                        <Grid
                            item
                            md={1.03}
                            sx={{ paddingTop: 3.3, borderTop: 0.5, borderColor: '#240E0C' }}
                        >
                            <Typography sx={textStyle}>Company</Typography>

                            <Grid item sx={{ lineHeight: '22px' }}>
                                <Link
                                    href={RouteCompnay}
                                    underline="none"
                                    target="_blank"
                                    rel="noreferrer"
                                    sx={subTextStyle}
                                >
                                    Careers
                                </Link>
                            </Grid>

                            <Grid item sx={{ lineHeight: '22px' }}>
                                <Link
                                    href={RouteAboutUs}
                                    underline="none"
                                    target="_blank"
                                    rel="noreferrer"
                                    sx={subTextStyle}
                                >
                                    About Us
                                </Link>
                            </Grid>

                            <Grid item sx={{ lineHeight: '22px' }}>
                                <Link
                                    href={RouteSupport}
                                    underline="none"
                                    target="_blank"
                                    rel="noreferrer"
                                    sx={subTextStyle}
                                >
                                    Support
                                </Link>
                            </Grid>
                        </Grid>

                        <Grid
                            item
                            md={6}
                            sx={{ paddingTop: 3.3, borderTop: 0.5, borderColor: '#240E0C' }}
                        >
                            <Typography sx={textStyle}>Legal</Typography>
                            <Grid item sx={{ lineHeight: '22px' }}>
                                <Link
                                    href={RouteTerms}
                                    underline="none"
                                    target="_blank"
                                    rel="noreferrer"
                                    sx={subTextStyle}
                                >
                                    Terms
                                </Link>
                            </Grid>
                            <Grid item sx={{ lineHeight: '22px' }}>
                                <Link
                                    href={RoutePrivacy}
                                    underline="none"
                                    target="_blank"
                                    rel="noreferrer"
                                    sx={subTextStyle}
                                >
                                    Privacy
                                </Link>
                            </Grid>
                            <Grid item sx={{ lineHeight: '22px' }}>
                                <Link
                                    href={RouteCookies}
                                    underline="none"
                                    target="_blank"
                                    rel="noreferrer"
                                    sx={subTextStyle}
                                >
                                    Cookies
                                </Link>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            md={3.25}
                            sx={{ paddingTop: 2.2, borderTop: 0.5, borderColor: '#240E0C' }}
                        >
                            <Grid
                                container
                                direction="row"
                                alignItems="center"
                                justifyContent="flex-end"
                                spacing={0.8}
                            >
                                <Grid item>
                                    <FooterLogo
                                        height={36}
                                        width={36}
                                        resProp={'9vh'}
                                        path={SETTINGS.DiscordLogo}
                                        link={RouteDiscord}
                                    />
                                </Grid>
                                <Grid item>
                                    {' '}
                                    <FooterLogo
                                        height={44}
                                        width={44}
                                        resProp={'9vh'}
                                        path={SETTINGS.LinkdinLogo}
                                        link={RouteLinkedIn}
                                    />
                                </Grid>
                                <Grid item sx={{ paddingBottom: 0.5 }}>
                                    {' '}
                                    <FooterLogo
                                        path={SETTINGS.FawkesImage}
                                        height={52}
                                        width={52}
                                        link={RouteHome}
                                        resProp={'9vh'}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item md={12}>
                                <Grid
                                    container
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="flex-end"
                                >
                                    <Grid item>
                                        <Typography
                                            sx={{
                                                fontFamily: '"Open Sans", sans-serif',
                                                fontSize: '0.75rem',
                                                marginRight: 1.5,
                                                lineHeight: '19.2px',
                                                marginTop: 1.5,
                                            }}
                                        >
                                            Fawkes Games Ltd © {new Date().getFullYear()}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        container
                                        item
                                        md={12}
                                        justifyContent="flex-end"
                                        sx={{ marginRight: 1.2 }}
                                    >
                                        <Link
                                            href="mailto:HELLO@FAWKES.GAMES"
                                            style={{
                                                textDecoration: 'none',
                                                fontSize: '1.1rem',
                                                marginRight: 1.5,
                                                textAlign: 'right',
                                                fontFamily: 'Alegreya Sans SC',
                                                fontWeight: 500,
                                            }}
                                        >
                                            <Icon
                                                icon={MailIcon}
                                                inline="true"
                                                style={{
                                                    color: SETTINGS.PRIMARY.main,
                                                    paddingTop: 2,
                                                }}
                                            />
                                            hello@fawkes.games
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </MHidden>
                <MHidden width="lgUp">
                    <Grid
                        container
                        sx={{
                            marginLeft: '14%',
                            marginTop: 4,
                            // borderTop: 0.5,
                            // borderColor: '#240E0C',
                        }}
                    >
                        <Grid
                            item
                            xs={3}
                            sx={{
                                paddingTop: 2.5,
                                paddingBottom: 8,
                                borderTop: 0.5,
                                borderColor: '#240E0C',
                            }}
                        >
                            <Typography sx={textStyle}>Company</Typography>

                            <Grid item sx={{ lineHeight: '22px' }}>
                                <Link
                                    href={RouteCompnay}
                                    underline="none"
                                    target="_blank"
                                    rel="noreferrer"
                                    sx={subTextStyle}
                                >
                                    Careers
                                </Link>
                            </Grid>

                            <Grid item sx={{ lineHeight: '22px' }}>
                                <Link
                                    href={RouteAboutUs}
                                    underline="none"
                                    target="_blank"
                                    rel="noreferrer"
                                    sx={subTextStyle}
                                >
                                    About Us
                                </Link>
                            </Grid>

                            <Grid item sx={{ lineHeight: '22px' }}>
                                <Link
                                    href={RouteSupport}
                                    underline="none"
                                    target="_blank"
                                    rel="noreferrer"
                                    sx={subTextStyle}
                                >
                                    Support
                                </Link>
                            </Grid>

                            <Typography
                                sx={{
                                    color: 'text.primary',
                                    fontFamily: 'Raleway, sans-serif',
                                    fontSize: '1rem',
                                    fontWeight: 700,
                                    lineHeight: '25.6px',
                                    marginTop: 4.5,
                                }}
                            >
                                Legal
                            </Typography>

                            <Grid item sx={{ lineHeight: '22px' }}>
                                <Link
                                    href={RouteTerms}
                                    underline="none"
                                    target="_blank"
                                    rel="noreferrer"
                                    sx={subTextStyle}
                                >
                                    Terms
                                </Link>
                            </Grid>
                            <Grid item sx={{ lineHeight: '22px' }}>
                                <Link
                                    href={RoutePrivacy}
                                    underline="none"
                                    target="_blank"
                                    rel="noreferrer"
                                    sx={subTextStyle}
                                >
                                    Privacy
                                </Link>
                            </Grid>
                            <Grid item sx={{ lineHeight: '22px' }}>
                                <Link
                                    href={RouteCookies}
                                    underline="none"
                                    target="_blank"
                                    rel="noreferrer"
                                    sx={subTextStyle}
                                >
                                    Cookies
                                </Link>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            xs={7}
                            sx={{ paddingTop: 2.2, borderTop: 0.5, borderColor: '#240E0C' }}
                        >
                            <Grid
                                container
                                direction="row"
                                alignItems="center"
                                justifyContent="flex-end"
                                spacing={0.8}
                            >
                                <Grid item>
                                    <FooterLogo
                                        height={36}
                                        width={36}
                                        resProp={'3vh'}
                                        path={SETTINGS.DiscordLogo}
                                        link={RouteDiscord}
                                    />
                                </Grid>
                                <Grid item>
                                    {' '}
                                    <FooterLogo
                                        height={44}
                                        width={44}
                                        resProp={'3vh'}
                                        path={SETTINGS.LinkdinLogo}
                                        link={RouteLinkedIn}
                                    />
                                </Grid>
                                <Grid item sx={{ paddingBottom: 0.5 }}>
                                    {' '}
                                    <FooterLogo
                                        path={SETTINGS.FawkesImage}
                                        height={52}
                                        width={52}
                                        link={RouteHome}
                                        resProp={'3vh'}
                                    />
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <Grid
                                    container
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="flex-end"
                                >
                                    <Grid item xs={12}>
                                        <Typography
                                            sx={{
                                                fontFamily: '"Open Sans", sans-serif',
                                                fontSize: '0.75rem',
                                                marginRight: 1.5,
                                                lineHeight: '19.2px',
                                                textAlign: 'right',
                                            }}
                                        >
                                            Fawkes Games Ltd © {new Date().getFullYear()}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        justifyContent="flex-end"
                                        sx={{ marginRight: 1.5 }}
                                    >
                                        <Link
                                            href="mailto:HELLO@FAWKES.GAMES"
                                            style={{
                                                textDecoration: 'none',
                                                textAlign: 'right',
                                                fontSize: '1rem',

                                                fontFamily: 'Alegreya Sans SC',
                                                fontWeight: 500,
                                            }}
                                        >
                                            <Icon
                                                icon={MailIcon}
                                                inline="true"
                                                style={{
                                                    color: SETTINGS.PRIMARY.main,
                                                    paddingTop: 2,
                                                }}
                                            />
                                            hello@fawkes.games
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </MHidden>
            </Box>
        </>
    );
}
