/*
    Imports
*/
import { Icon } from '@iconify/react';
import {
    Alert,
    Box,
    Button,
    Checkbox,
    Collapse,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Field, Form, FormikProvider, useFormik } from 'formik';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import DatePicker from '@mui/lab/DatePicker';
import Autocomplete from '@mui/material/Autocomplete';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import Dialog from 'src/components/misc/alerts/Dialog';
import LoadingFormButton from 'src/components/misc/Buttons/LoadingFormButton';
import { RegisterSchema } from 'src/config/form-schemas';
import {
    EmailIcon,
    ErrorDialogIcon,
    HidePasswordIcon,
    PasswordIcon,
    ShowPasswordIcon,
    SuccessDialogIcon,
    UsersIcon,
} from 'src/config/icons';
import { asciiRegExp } from 'src/config/regex';
import { RouteLogin, RoutePrivacy, RouteTerms } from 'src/config/routes';
import { countryList } from 'src/constants';
import userService from 'src/services/UserServiceClass';
import palette from 'src/theme/palette';
import ReCAPTCHA from 'react-google-recaptcha';
import { SETTINGS } from 'src/config/settings';
/*
    Main Working
*/
export default ({}) => {
    /*
      States, Params, Navigation, Query.
    */
    const [serverError, setServerError] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [openDia, setOpenDia] = useState(false);
    const [showPasswordError, setShowPasswordError] = useState(false);

    const captchaRef = useRef(null);

    const verify = () => {
        captchaRef.current.getResponse().then((res) => {
            setCaptchaToken(res);
        });
    };

    const navigate = useNavigate();

    /*
      Form Setup
    */
    const formik = useFormik({
        initialValues: {
            email: '',
            username: '',
            first_name: '',
            last_name: '',
            password: '',
            confirm: '',
            dob: '',
            country: '',
            emails_enabled: true,
            terms: false,
        },
        validationSchema: RegisterSchema,
        onSubmit: (values, { setSubmitting, setFieldError }) => {
            const token = captchaRef.current.getValue();
            if (!token) {
                setServerError('Please verify that you are not a robot');
                setSubmitting(false);
                return;
            }
            userService
                .register({
                    ...values,
                    country: values.country.Code,
                    dob: `${values.dob.getFullYear()}-${
                        values.dob.getMonth() + 1
                    }-${values.dob.getDate()}`,
                    token: token,
                })
                .then((_res) => {
                    setOpenDia(true);
                })
                .catch((error) => {
                    console.error('Error', error);
                    setSubmitting(false);
                    if (!error.response) {
                        setServerError('Error occurred please try later');
                    } else {
                        setServerError(error.response.data.msg);
                    }
                })
                .finally(() => {
                    captchaRef.current.reset();
                });
        },
    });
    const {
        errors,
        touched,
        isSubmitting,
        handleSubmit,
        getFieldProps,
        setFieldValue,
        resetForm,
        values,
    } = formik;

    const handleClose = () => {
        resetForm();
        setOpenDia(false);
        navigate(RouteLogin);
    };

    /*
      Handlers
    */

    const handleShowPassword = () => {
        setShowPassword((show) => !show);
    };

    const pass_length_e = values.password.length < 8;
    const pass_mlength_e = values.password.length > 16;
    const pass_ascii_e = !/^[a-zA-Z0-9#!\*%\-_=]+$/.test(values.password);
    const pass_upper_e = !/[A-Z]/.test(values.password);
    const pass_lower_e = !/[a-z]/.test(values.password);
    const pass_number_e = !/[0-9]/.test(values.password);
    const pass_special_e = !/[#!\*%\-_=]/.test(values.password);
    const pass_space_e = !/^[^\s]*$/.test(values.password);
    /*
      Main Design
    */
    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={6}>
                    <TextField
                        fullWidth
                        type="text"
                        label="First Name"
                        {...getFieldProps('first_name')}
                        error={Boolean(touched.first_name && errors.first_name)}
                        helperText={touched.first_name && errors.first_name}
                    />

                    <TextField
                        fullWidth
                        type="text"
                        label="Last Name"
                        {...getFieldProps('last_name')}
                        error={Boolean(touched.last_name && errors.last_name)}
                        helperText={touched.last_name && errors.last_name}
                    />

                    <TextField
                        fullWidth
                        type="email"
                        label="Email"
                        {...getFieldProps('email')}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Icon icon={EmailIcon} inline="true" style={{ fontSize: 20 }} />
                                </InputAdornment>
                            ),
                        }}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                    />

                    <TextField
                        fullWidth
                        type="text"
                        label="Username"
                        {...getFieldProps('username')}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Icon icon={UsersIcon} inline="true" style={{ fontSize: 20 }} />
                                </InputAdornment>
                            ),
                        }}
                        error={Boolean(touched.username && errors.username)}
                        helperText={touched.username && errors.username}
                    />

                    <TextField
                        fullWidth
                        type={showPassword ? 'text' : 'password'}
                        label="Password"
                        {...getFieldProps('password')}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleShowPassword} edge="end">
                                        <Icon
                                            icon={
                                                showPassword ? HidePasswordIcon : ShowPasswordIcon
                                            }
                                        />
                                    </IconButton>
                                </InputAdornment>
                            ),
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Icon
                                        icon={PasswordIcon}
                                        inline="true"
                                        style={{ fontSize: 20 }}
                                    />
                                </InputAdornment>
                            ),
                        }}
                        error={Boolean(touched.password && errors.password)}
                        // helperText={touched.password && errors.password}

                        onFocus={() => setShowPasswordError(true)}
                    />
                    {showPasswordError && (
                        <Box
                            style={{ marginTop: 0, marginBottom: 0 }}
                            sx={{
                                p: 1,
                                pl: 2,
                                bgcolor: palette.background.default,
                                // border: 2,
                                // borderColor: "primary.main",
                            }}
                        >
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                <Icon
                                    icon={pass_length_e ? ErrorDialogIcon : SuccessDialogIcon}
                                    inline="true"
                                    style={{
                                        fontSize: 14,
                                        color: pass_length_e
                                            ? palette.error.main
                                            : palette.success.main,
                                    }}
                                ></Icon>{' '}
                                More than 8 characters. <br />
                                <Icon
                                    icon={pass_mlength_e ? ErrorDialogIcon : SuccessDialogIcon}
                                    inline="true"
                                    style={{
                                        fontSize: 14,
                                        color: pass_mlength_e
                                            ? palette.error.main
                                            : palette.success.main,
                                    }}
                                ></Icon>{' '}
                                Less than 16 characters. <br />
                                <Icon
                                    icon={pass_upper_e ? ErrorDialogIcon : SuccessDialogIcon}
                                    inline="true"
                                    style={{
                                        fontSize: 14,
                                        color: pass_upper_e
                                            ? palette.error.main
                                            : palette.success.main,
                                    }}
                                ></Icon>{' '}
                                At least one uppercase letter. <br />
                                <Icon
                                    icon={pass_lower_e ? ErrorDialogIcon : SuccessDialogIcon}
                                    inline="true"
                                    style={{
                                        fontSize: 14,
                                        color: pass_lower_e
                                            ? palette.error.main
                                            : palette.success.main,
                                    }}
                                ></Icon>{' '}
                                At least one lowercase letter. <br />
                                <Icon
                                    icon={pass_number_e ? ErrorDialogIcon : SuccessDialogIcon}
                                    inline="true"
                                    style={{
                                        fontSize: 14,
                                        color: pass_number_e
                                            ? palette.error.main
                                            : palette.success.main,
                                    }}
                                ></Icon>{' '}
                                At least one number (0-9). <br />
                                <Icon
                                    icon={pass_special_e ? ErrorDialogIcon : SuccessDialogIcon}
                                    inline="true"
                                    style={{
                                        fontSize: 14,
                                        color: pass_special_e
                                            ? palette.error.main
                                            : palette.success.main,
                                    }}
                                ></Icon>{' '}
                                At least 1 special character from: #, !, *, %, -, _ or = <br />
                                <Icon
                                    icon={pass_ascii_e ? ErrorDialogIcon : SuccessDialogIcon}
                                    inline="true"
                                    style={{
                                        fontSize: 14,
                                        color: pass_ascii_e
                                            ? palette.error.main
                                            : palette.success.main,
                                    }}
                                ></Icon>{' '}
                                Can't contain special letters (ü, Æ, ñ, ß, Ø, etc.) <br />
                                <Icon
                                    icon={pass_space_e ? ErrorDialogIcon : SuccessDialogIcon}
                                    inline="true"
                                    style={{
                                        fontSize: 14,
                                        color: pass_space_e
                                            ? palette.error.main
                                            : palette.success.main,
                                    }}
                                ></Icon>{' '}
                                Can't contain spaces. <br />
                            </Typography>
                        </Box>
                    )}

                    <TextField
                        fullWidth
                        type={showPassword ? 'text' : 'password'}
                        label="Confirm Password"
                        {...getFieldProps('confirm')}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleShowPassword} edge="end">
                                        <Icon
                                            icon={
                                                showPassword ? HidePasswordIcon : ShowPasswordIcon
                                            }
                                        />
                                    </IconButton>
                                </InputAdornment>
                            ),
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Icon
                                        icon={PasswordIcon}
                                        inline="true"
                                        style={{ fontSize: 20 }}
                                    />
                                </InputAdornment>
                            ),
                        }}
                        error={Boolean(touched.confirm && errors.confirm)}
                        helperText={touched.confirm && errors.confirm}
                    />

                    {/* <TextField
                        fullWidth
                        type="date"
                        label="Date of Birth"
                        {...getFieldProps('dob')}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Icon icon={DateIcon} inline="true" style={{ fontSize: 20 }} />
                                </InputAdornment>
                            ),
                        }}
                        error={Boolean(touched.dob && errors.dob)}
                        helperText={touched.dob && errors.dob}
                    /> */}

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            inputFormat="MM/dd/yyyy"
                            label="Birthday"
                            value={values.dob}
                            onChange={(e) => setFieldValue('dob', e)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    // InputProps={{
                                    //     startAdornment: (
                                    //         <InputAdornment position="start">
                                    //             <Icon icon={DateIcon} inline="true" style={{ fontSize: 20 }} />
                                    //         </InputAdornment>
                                    //     ),
                                    // }}
                                    error={Boolean(touched.dob && errors.dob)}
                                    helperText={touched.dob && errors.dob}
                                />
                            )}
                        />
                    </LocalizationProvider>

                    <Autocomplete
                        id="highlights-demo"
                        // sx={{ width: 300 }}
                        options={countryList}
                        value={formik.values.country}
                        getOptionLabel={(option) => option?.Name || ''}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Select a Country"
                                margin="normal"
                                error={Boolean(touched.country && errors.country)}
                                helperText={touched.country && errors.country}
                            />
                        )}
                        onChange={(event, newValue) => {
                            setFieldValue('country', newValue);
                        }}
                        renderOption={(props, option, { inputValue }) => {
                            const matches = match(option?.Name, inputValue, {
                                insideWords: true,
                            });
                            const parts = parse(option.Name, matches);

                            return (
                                <li {...props}>
                                    <div>
                                        {parts.map((part, index) => (
                                            <span
                                                key={index}
                                                style={{
                                                    fontWeight: part.highlight ? 700 : 400,
                                                }}
                                            >
                                                {part.text}
                                            </span>
                                        ))}
                                    </div>
                                </li>
                            );
                        }}
                    />
                </Stack>

                <br />

                <Stack spacing={0} direction="row">
                    <Field
                        // fullWidth
                        type="checkbox"
                        {...getFieldProps('emails_enabled')}
                        as={FormControlLabel}
                        control={<Checkbox checked={values.emails_enabled} />}
                    />
                    <Typography variant="body2" color="text.secondary">
                        I would like to receive communications from Fawkes, including exclusive
                        discounts, game(s) updates, and important game related content in general.
                    </Typography>
                </Stack>
                <br />
                <Stack spacing={0} direction="row">
                    <Field
                        // fullWidth
                        type="checkbox"
                        {...getFieldProps('terms')}
                        as={FormControlLabel}
                        control={<Checkbox checked={values.terms} />}
                    />
                    <Typography variant="body2" color="text.secondary">
                        I agree with{' '}
                        <a
                            href={RouteTerms}
                            style={{ textDecoration: 'none' }}
                            target="_blank"
                            rel="noreferrer"
                        >
                            Terms of Service
                        </a>{' '}
                        and{' '}
                        <a
                            href={RoutePrivacy}
                            style={{ textDecoration: 'none' }}
                            target="_blank"
                            rel="noreferrer"
                        >
                            Privacy Policy
                        </a>
                        .
                    </Typography>
                </Stack>
                {/* {errors.terms && touched.terms && (
                    <Typography variant="body2" color="error">
                        You need to accept our Terms of Service and Privacy Policy.
                    </Typography>
                )} */}

                <br />
                <ReCAPTCHA
                    sitekey={SETTINGS.RECAPTCHA.SITE_KEY}
                    ref={captchaRef}
                    onVerify={verify}
                    theme="dark"
                />
                <br />
                <Grid container spacing={{ md: 3 }}>
                    <Grid item xs={12} sm={6} md={6} style={{ marginBottom: 20 }}>
                        <Dialog
                            buttonText={'Close'}
                            openDialog={openDia}
                            handleButton={handleClose}
                            style={{ color: 'white' }}
                        >
                            Account created successfully. <b>Confirm your email address</b> to be
                            able to access it.
                        </Dialog>

                        <LoadingFormButton
                            style={{ padding: '0px 70px' }}
                            size="large"
                            type="submit"
                            variant="contained"
                            loading={isSubmitting}
                        >
                            Register
                        </LoadingFormButton>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} style={{ marginBottom: 20 }}>
                        <Button
                            style={{ padding: '0px 80px' }}
                            size="large"
                            type="submit"
                            variant="outlined"
                            loading={isSubmitting.toString()}
                            href={RouteLogin}
                        >
                            Login
                        </Button>
                    </Grid>
                </Grid>

                {touched.terms && !!errors.terms && (
                    <Stack marginTop={3}>
                        <Collapse in={!!errors.terms}>
                            <Alert severity="error">
                                You need to accept our Terms of Service and Privacy Policy.
                            </Alert>
                        </Collapse>
                    </Stack>
                )}

                {serverError && (
                    <Stack marginTop={3}>
                        <Collapse in={!!serverError}>
                            <Alert severity="error">{serverError}</Alert>
                        </Collapse>
                    </Stack>
                )}
            </Form>
        </FormikProvider>
    );
};
