import { useState, useEffect } from 'react';
import { Grid, Typography, Box, Link } from '@mui/material';
import { Icon } from '@iconify/react';
import { Blocked } from 'src/config/icons';
import palette from 'src/theme/palette';

const ExpiryComponent = ({ expireDate, reason }) => {
    const getExpiryTime = (expireDate) => {
        const now = new Date();
        const expiry = new Date(expireDate);
        const timeDiff = Math.abs(expiry - now);

        // Calculate days left until expiry
        const daysLeft = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
        // Calculate remaining milliseconds after days
        const remainingMs = timeDiff % (1000 * 60 * 60 * 24);
        // Calculate hours left within the current day
        const hoursLeft = Math.floor(remainingMs / (1000 * 60 * 60));
        // Calculate remaining milliseconds after hours
        const remainingMsAfterHours = remainingMs % (1000 * 60 * 60);
        // Calculate minutes left within the current hour
        const minutesLeft = Math.floor(remainingMsAfterHours / (1000 * 60));

        return {
            days: daysLeft,
            hours: hoursLeft,
            minutes: minutesLeft,
        };
    };

    const [expiryTime, setExpiryTime] = useState(expireDate ? getExpiryTime(expireDate) : null);

    useEffect(() => {
        if (expireDate) {
            const interval = setInterval(() => {
                setExpiryTime(getExpiryTime(expireDate));
            }, 1000); // Update every second

            return () => clearInterval(interval);
        }
    }, [expireDate]);

    if (reason == 'Migration Pending Approval') {
        reason =
            ' <b>Migration Pending Approval</b><br/> Please, allow up to 72h for our team to approve your account migration.';
    }

    if (!expireDate) {
        return (
            <>
                <Grid item>
                    <Box display="flex" alignItems="center">
                        <Icon icon={Blocked} color="#FF0000" height="32" width="32" />
                        <Typography sx={{ marginLeft: 1, fontWeight: 'bold' }}>Blocked</Typography>
                    </Box>
                </Grid>
                <Grid item sx={{ marginLeft: 1, marginTop: 5 }}>
                    <Typography>
                        Reason:{' '}
                        <span
                            dangerouslySetInnerHTML={{
                                __html: reason,
                            }}
                        >
                            {/* {reason} */}
                        </span>
                    </Typography>
                </Grid>
                <Grid item sx={{ marginLeft: 1, marginTop: 5 }}>
                    <Typography>
                        Get in touch with{' '}
                        <Link
                            href="https://fawkes.freshdesk.com/support/home"
                            target="_blank"
                            sx={{ textDecoration: 'none' }}
                        >
                            support
                        </Link>{' '}
                        if you have any questions related with your account status.
                    </Typography>
                </Grid>
            </>
        );
    }

    return (
        <>
            <Grid item>
                <Box display="flex" alignItems="center">
                    <Icon icon={Blocked} color="#FF0000" height="32" width="32" />
                    <Typography sx={{ marginLeft: 1, fontWeight: 'bold' }}>Blocked</Typography>
                </Box>
            </Grid>
            <Grid item sx={{ marginLeft: 1, marginTop: 4 }}>
                Time Left:{' '}
                <span>
                    {expiryTime.days === 0
                        ? `${expiryTime.hours.toString().padStart(2, '0')}h ${expiryTime.minutes
                              .toString()
                              .padStart(2, '0')}m`
                        : `${expiryTime.days.toString().padStart(2, '0')}d ${expiryTime.hours
                              .toString()
                              .padStart(2, '0')}h ${expiryTime.minutes
                              .toString()
                              .padStart(2, '0')}m`}
                </span>
            </Grid>
            <Grid item sx={{ marginLeft: 1 }}>
                <Typography>
                    Reason: <span>{reason}</span>
                </Typography>
            </Grid>
            <Grid item sx={{ marginLeft: 1, marginTop: 4 }}>
                <Typography>
                    Get in touch with{' '}
                    <Link
                        href="https://fawkes.freshdesk.com/support/home"
                        target="_blank"
                        sx={{ textDecoration: 'none' }}
                    >
                        support
                    </Link>{' '}
                    if you have any questions related with your account status.
                </Typography>
            </Grid>
        </>
    );
};

export default ExpiryComponent;
