import { Alert, Collapse, Stack } from '@mui/material';

export default ({ open, children, collapse, severity = 'error', sx, sxA, icon }) => {
    return (
        <>
            {open && (
                <Stack marginTop={3} sx={{ width: '50%', ...sx }}>
                    {collapse ? (
                        <Collapse in={collapse}>
                            <Alert sx={{ ...sxA }} severity={severity} icon={icon}>
                                {children}
                            </Alert>
                        </Collapse>
                    ) : (
                        <Alert sx={{ ...sxA }} severity={severity} icon={icon}>
                            {children}
                        </Alert>
                    )}
                </Stack>
            )}
        </>
    );
};
