import PropTypes from 'prop-types';
// material
import { alpha, styled } from '@mui/material/styles';

// ----------------------------------------------------------------------

const RootStyle = styled('span')(({ theme, styleprops }) => {
    const { color, variant } = styleprops;

    const styleFilled = (_color) => ({
        color: theme.palette[_color].contrastText,
        backgroundColor: theme.palette[_color].main,
    });

    const styleOutlined = (_color) => ({
        color: theme.palette[_color].main,
        backgroundColor: 'transparent',
        border: `1px solid ${theme.palette[_color].main}`,
    });

    const styleGhost = (_color) => ({
        color: theme.palette[_color].dark,
        backgroundColor: alpha(theme.palette[_color].main, 0.16),
    });

    return {
        height: 22,
        minWidth: 22,
        lineHeight: 0,
        borderRadius: 8,
        cursor: 'default',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        display: 'inline-flex',
        justifyContent: 'center',
        padding: theme.spacing(0, 1),
        color: theme.palette.grey[800],
        fontSize: theme.typography.pxToRem(12),
        fontFamily: theme.typography.fontFamily,
        backgroundColor: theme.palette.grey[300],
        fontWeight: theme.typography.fontWeightBold,

        ...(color !== 'default'
            ? {
                  ...(variant === 'filled' && { ...styleFilled(color) }),
                  ...(variant === 'outlined' && { ...styleOutlined(color) }),
                  ...(variant === 'ghost' && { ...styleGhost(color) }),
              }
            : {
                  ...(variant === 'outlined' && {
                      backgroundColor: 'transparent',
                      color: theme.palette.text.primary,
                      border: `1px solid ${theme.palette.grey[500_32]}`,
                  }),
                  ...(variant === 'ghost' && {
                      color: theme.palette.text.secondary,
                      backgroundColor: theme.palette.grey[500_16],
                  }),
              }),
    };
});

// ----------------------------------------------------------------------

export default function Label({ color = 'default', variant = 'ghost', children, ...other }) {
    return (
        <RootStyle styleprops={{ color, variant }} {...other}>
            {children}
        </RootStyle>
    );
}

Label.propTypes = {
    children: PropTypes.node,
    color: PropTypes.oneOf([
        'default',
        'primary',
        'secondary',
        'info',
        'success',
        'warning',
        'error',
    ]),
    variant: PropTypes.oneOf(['filled', 'outlined', 'ghost']),
};
