import { Box, Card, Grid, Stack, Typography } from '@mui/material';
import GemImage from 'src/components/GemImage';
import LoadingFormButton from '../Buttons/LoadingFormButton';
import { GemsAvatar } from './GemsAvatar';

export default ({ item, handleClick, loading, disabled }) => {
    const { name, price, image, quantity } = item;

    const handleBuy = () => {
        handleClick(item);
    };

    return (
        <Card sx={{ width: '100%' }}>
            <Grid
                container
                spacing={1}
                justifyContent="center"
                alignItems="center"
                style={{ paddingBottom: 10, paddingTop: 10 }}
            >
                <Grid item>
                    <GemImage />
                </Grid>
                <Grid item>
                    <Typography sx={{ textAlign: 'center' }} variant="h3" noWrap>
                        {quantity}
                    </Typography>
                </Grid>
            </Grid>
            <Box sx={{ pt: '60%', position: 'relative', pb: 0, mb: -3, mt: '5%' }}>
                <GemsAvatar alt={name} src={image} />
            </Box>

            <Stack spacing={2} sx={{ p: 3, pt: 0, mt: 0 }}>
                <Typography sx={{ textAlign: 'center' }} variant="h3" noWrap>
                    $ {price}
                </Typography>

                <LoadingFormButton
                    onClick={handleBuy}
                    disabled={disabled}
                    loading={loading}
                    size="large"
                    type="button"
                    variant="contained"
                >
                    Buy Now
                </LoadingFormButton>
            </Stack>
        </Card>
    );
};
