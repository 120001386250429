/*
    Imports
*/
import { Alert, Collapse, Grid, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import Dialog from 'src/components/misc/alerts/Dialog';
import LoadingFormButton from 'src/components/misc/Buttons/LoadingFormButton';
import userService from 'src/services/UserServiceClass';

/*
    Main Working
*/

export default ({ user }) => {
    /*
      States, Params, Navigation, Query.
    */
    const [serverError, setServerError] = useState('');
    const [usernameSent, setUsernameSent] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [openDia, setOpenDia] = useState(false);

    /*
      Form Setup
    */

    const forgetPassword = () => {
        setServerError('');
        setSubmit(true);
        userService
            .forgetPassword({ username: user.username })
            .then((res) => {
                setUsernameSent(user.username);
                setOpenDia(true);
            })
            .catch((err) => {
                console.error('Error Reset Button', err);
                if (!err.response) {
                    setServerError('Error occurred please try later');
                } else {
                    setServerError(err.response.data.msg);
                }
            })
            .finally(() => setSubmit(false));
    };

    const handleClose = () => {
        setOpenDia(false);
    };

    return (
        <>
            <Grid container>
                <Grid item xs={12} md={12} sx={{ marginTop: 3, marginBottom: 4 }}>
                    <Typography variant="h6">Change Password</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={0} sx={{ marginTop: 2 }}>
                <Grid item xs={12} md={6}>
                    <LoadingFormButton
                        size="large"
                        type="button"
                        variant="outlined"
                        loading={submit}
                        onClick={forgetPassword}
                        sx={{ p: '0px 37px' }}
                    >
                        Password Change
                    </LoadingFormButton>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                    <Dialog
                        buttonText={'Close'}
                        openDialog={openDia}
                        handleButton={handleClose}
                        style={{ color: 'white' }}
                    >
                        Check your email inbox to proceed with the password change.
                    </Dialog>
                </Grid>
            </Grid>

            {serverError && (
                <Stack sx={{}} marginTop={3}>
                    <Collapse in={!!serverError}>
                        <Alert severity="error">{serverError}</Alert>
                    </Collapse>
                </Stack>
            )}
        </>
    );
};
