/*
    Imports
*/
import { Icon } from '@iconify/react';
import { Alert, Collapse, InputAdornment, Stack, TextField } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import Dialog from 'src/components/misc/alerts/Dialog';
import LoadingFormButton from 'src/components/misc/Buttons/LoadingFormButton';
import { ForgetSchema } from 'src/config/form-schemas';
import { UsersIcon } from 'src/config/icons';
import userService from '../../services/UserServiceClass';

/*
    Main Working
*/
export default () => {
    /*
        States, Params, Navigation, Query.
    */
    const [serverError, setServerError] = useState('');
    const [usernameSent, setUsernameSent] = useState(false);
    const [openDia, setOpenDia] = useState(false);

    const [isEmail, setIsEmail] = useState(false);

    /*
        Form Setup
    */
    const formik = useFormik({
        initialValues: {
            username: '',
        },
        validationSchema: ForgetSchema(isEmail),
        onSubmit: (values, { setSubmitting, setFieldError }) => {
            setServerError('');
            userService
                .forgetPassword({ ...values })
                .then((res) => {
                    setUsernameSent(values.username);
                    setOpenDia(true);
                })
                .catch((err) => {
                    console.error('Error', err.response);
                    if (err.response?.status === 404) setFieldError('username', 'No user found');
                    else setServerError(err.response?.data?.msg ?? 'Something went wrong');
                })
                .finally(() => setSubmitting(false));
        },
    });
    const { errors, touched, isSubmitting, handleSubmit, getFieldProps, resetForm, values } = formik;

    /*
        Handlers
    */
    const handleClose = () => {
        resetForm();
        setOpenDia(false);
    };

    useEffect(() => {
        setIsEmail(values.username.includes('@'));
    }, [values.username])

    /*
        Main Design
    */
    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                    <TextField
                        type="text"
                        label="Username or Email"
                        {...getFieldProps('username')}
                        // inputProps={{
                        //     inputMode: 'email',
                        // }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Icon icon={UsersIcon} inline="true" style={{ fontSize: 25 }} />
                                </InputAdornment>
                            ),
                        }}
                        error={Boolean(touched.username && errors.username)}
                        helperText={touched.username && errors.username}
                    />
                </Stack>

                <br />
                <Dialog
                    buttonText={'Close'}
                    openDialog={openDia}
                    handleButton={handleClose}
                    style={{ color: 'white' }}
                >
                    {`A link is sent to ${usernameSent}`}
                </Dialog>

                <LoadingFormButton
                    style={{ padding: '0px 70px' }}
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}>Send</LoadingFormButton>

                {serverError && (
                    <Stack sx={{ width: '50%' }} marginTop={3}>
                        <Collapse in={!!serverError}>
                            <Alert severity="error">{serverError}</Alert>
                        </Collapse>
                    </Stack>
                )}
            </Form>
        </FormikProvider>
    );
};
