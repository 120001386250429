import { Icon } from '@iconify/react';
import {
    AccountIcon,
    DiscordIcon,
    GemIcon,
    HomeIcon,
    LogoutIcon,
    MigrationIcon,
    RedeemCodeIcon,
    SecurityIcon,
    TransactionIcon,
    GamesIcon,
    NewsIcon,
    StoreIcon,
    LoyaltyIcon,
    RankingStar,
    Armory,
    CharacterOptimization,
} from 'src/config/icons';

import {
    RouteAccounts,
    RouteCharacterOptimization,
    RouteDiscord,
    RouteGems,
    RouteHome,
    RouteLastChaos,
    RouteLogout,
    RouteLoyaltyCards,
    RouteMigration,
    RouteNews,
    RouteRanking,
    RouteRedeemCode,
    RouteSecurity,
    RouteShaiya,
    RouteStore,
    RouteTransaction,
} from 'src/config/routes';
import { SETTINGS } from './settings';

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

export const sidebarConfig = {
    mobile: [
        {
            title: 'Home',
            path: RouteHome,
            icon: getIcon(HomeIcon),
            outside: true,
        },
        {
            title: 'News',
            path: RouteNews,
            icon: getIcon(NewsIcon),
            outside: true,
        },
        {
            title: 'Games',
            icon: getIcon(GamesIcon),
            children: [
                {
                    title: 'Shaiya',
                    path: RouteShaiya,
                    icon: getIcon(GamesIcon),
                    outside: true,
                },
                {
                    title: 'Last Chaos',
                    path: RouteLastChaos,
                    icon: getIcon(GamesIcon),
                    outside: true,
                },
            ],
        },
        {
            title: 'Discord',
            path: RouteDiscord,
            icon: getIcon(DiscordIcon),
            outside: true,
        },
        // {
        //     title: 'Store',
        //     path: RouteStore,
        //     icon: getIcon(StoreIcon),
        //     outside: true,
        // },
    ],

    top: [
        {
            title: 'Account',
            icon: getIcon(AccountIcon),
            children: [
                {
                    title: 'General',
                    path: RouteAccounts,
                    icon: getIcon(AccountIcon),
                },
                {
                    title: 'Security',
                    path: RouteSecurity,
                    icon: getIcon(SecurityIcon),
                },
                {
                    title: 'Migration',
                    path: RouteMigration,
                    icon: getIcon(MigrationIcon),
                },
                {
                    title: 'Redeem Code',
                    path: RouteRedeemCode,
                    icon: getIcon(RedeemCodeIcon),
                },
            ],
        },

        {
            title: 'Store',
            icon: getIcon(StoreIcon),
            game: [SETTINGS.GAMES[0].id, SETTINGS.GAMES[1].id],
            children: [
                {
                    title: 'Fawkes Gems',
                    path: RouteGems,
                    icon: getIcon(GemIcon),
                },
                {
                    title: 'Loyalty Rewards',
                    path: RouteLoyaltyCards,
                    icon: getIcon(LoyaltyIcon),
                    game: [SETTINGS.GAMES[0].id, SETTINGS.GAMES[1].id],
                },
                {
                    title: 'Transactions',
                    path: RouteTransaction,
                    icon: getIcon(TransactionIcon),
                },
            ],
        },

        {
            title: 'Armory',
            icon: getIcon(Armory),
            game: [SETTINGS.GAMES[0].id, SETTINGS.GAMES[1].id],
            children: [
                {
                    title: 'Rankings',
                    path: RouteRanking,
                    icon: getIcon(RankingStar),
                    game: SETTINGS.GAMES[1].id,
                },
                {
                    title: 'Character Optimization',
                    path: RouteCharacterOptimization,
                    icon: getIcon(CharacterOptimization),
                },
            ],
        },
    ],

    bottom: [
        {
            title: 'Logout',
            path: RouteLogout,
            icon: getIcon(LogoutIcon),
        },
    ],

    bottomNoAuth: [],
};
