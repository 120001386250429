import differenceInYears from 'date-fns/differenceInYears';
import * as Yup from 'yup';
import { asciiRegExp } from './regex';

export const ResetSchema = (password) =>
    Yup.object().shape({
        password: Yup.string()
            .required('Password is required')
            .min(8, 'Password must be atleast 8 characters')
            .max(16, 'Password must not be more than 16 characters')
            .matches(/[a-z]/, 'Password must contain at least one lowercase character')
            .matches(/[A-Z]/, 'Password must contain at least one uppercase character')
            .matches(/[0-9]/, 'Password must contain at least one number')
            .matches(/[#!*%\-_=]/, 'Password must contain at least one special character')
            .matches(/^[^\s]*$/, 'Password must not contain spaces')
            .matches(/^[A-Za-z0-9#!*%\-=_]{8,16}$/, 'Invalid Characters in Password'),

        confirm: Yup.string()
            .equals([password], 'Passwords do not match')
            .required('Confirm Password is required'),
    });

export const RegisterSchema = Yup.object().shape({
    email: Yup.string().required('Email is required').email('Enter correct email'),
    username: Yup.string()
        .required('Username is required')
        .min(7, 'Username must be atleast 7 characters')
        .max(13, 'Username must not be more than 13 characters')
        .matches(/^[A-Za-z_0-9]+$/, 'Username must be in ASCII'),
    password: Yup.string()
        .required('Password is required')
        .min(8, 'Password must be atleast 8 characters')
        .max(16, 'Password must not be more than 16 characters')
        .matches(/[a-z]/, 'Password must contain at least one lowercase character')
        .matches(/[A-Z]/, 'Password must contain at least one uppercase character')
        .matches(/[0-9]/, 'Password must contain at least one number')
        .matches(/[#!\*%\-_=]/, 'Password must contain at least one special character')
        .matches(/^[^\s]*$/, 'Password must not contain spaces')
        .matches(/^[A-Za-z0-9#!\*%\-=_]{8,16}$/, 'Invalid Characters in Password'),
    confirm: Yup.string()
        .required('Confirm password is required')
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    dob: Yup.date()
        .required('Date of Birth is required')
        .test('dob', 'Should be greater than 13', function (value) {
            return differenceInYears(new Date(), new Date(value)) >= 13;
        }),
    country: Yup.object().typeError('Please select a country').required('Country is required'),
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    terms: Yup.boolean().required('Emails Enabled is required').oneOf([true]),
});

export const ForgetSchema = (isEmail) => {
    if (!isEmail)
        return Yup.object().shape({
            username: Yup.string()
                .required('Username is required')
                .min(7, 'Username must be atleast 7 characters')
                .max(13, 'Username must not be more than 13 characters')
                .matches(asciiRegExp, 'Username must be in ASCII'),
        });

    return Yup.object().shape({
        username: Yup.string().required('Email is required').email('Enter correct email'),
    });
};
export const LoginSchema = Yup.object().shape({
    username: Yup.string().required('Username or Email is required'),
    password: Yup.string().required('Password is required'),
});

export const AccountSchema = Yup.object().shape({
    discord: Yup.string()
        .required('Discord is required'),
    country: Yup.object().typeError('Please select a country').required('Country is required'),
});
export const MigrationSchema = Yup.object().shape({
    code: Yup.string().required('Code is required'),
});
