/*
  Imports
*/
import {
    Card,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    Collapse,
    Typography,
    Box,
    Tooltip,
} from '@mui/material';
import { Icon } from '@iconify/react';
import { filter } from 'lodash';
import { useState } from 'react';
import { SETTINGS } from 'src/config/settings';
import palette from 'src/theme/palette';
import { getComparator, stabilize } from 'src/utils/table';
import SearchNotFound from '../../components/misc/alerts/SearchNotFound';
import { UserListHead, UserListToolbar } from '../../components/misc/table';
import Scrollbar from '../../components/Scrollbar';
import { fNumber } from 'src/utils/formatNumber';
import CenterLoading from 'src/components/misc/CenterLoading';
import Label from 'src/components/misc/Label';
import AreaCharts from 'src/charts/AreaCharts';

import dayjs from 'dayjs';
import { Icon1stPlaceMedal, Icon2ndPlaceMedal, Icon3rdPlaceMedal } from 'src/config/icons';
import RankTooltip from 'src/components/RankTooltip';

/*
  Global Variables, Functions
*/

/*
  Main Working
*/

function applySortFilter(array, comparator, query) {
    let toSort = array;
    if (query) {
        toSort = filter(
            array,
            (element) =>
                String(element.Kills).toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
                //String(element.CharacterID).toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
                String(element.CharacterName).toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
                String(element.Rank).toLowerCase().indexOf(query.toLowerCase()) !== -1,
        );
    }
    return stabilize(toSort, comparator);
}

export default ({
    data,
    loading,
    handleClick,
    openRow,
    summary,
    summaryLoading,
    chartData,
    selector,
}) => {
    const TableHeader = [
        // { id: 'CharacterID', label: 'ID', align: 'left' },
        { id: 'Rank', label: 'Rank', align: 'left' },
        { id: 'CharacterName', label: 'Character Name', align: 'left' },
        { id: 'Kills', label: 'Kills', align: 'left' },
        { id: 'Side', label: 'Faction', align: 'left' },
        { id: 'Class', label: 'Class', align: 'left' },
    ];

    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState(TableHeader[0].id);
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(100);

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;
    const filteredUsers = applySortFilter(data, getComparator(order, orderBy), filterName);
    const isUserNotFound = filteredUsers.length === 0;

    /*
    Handlers, Functions
  */

    const handleRequestSort = (_event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = data.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (_event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByName = (event) => {
        const query = event.target.value;
        setFilterName(query);
    };

    /*
    Main Design.
  */
    return (
        <>
            {loading ? (
                <CenterLoading />
            ) : (
                <>
                    <Card>
                        <UserListToolbar
                            numSelected={selected.length}
                            filterName={filterName}
                            onFilterName={handleFilterByName}
                        />
                        <Scrollbar>
                            <TableContainer sx={{ minWidth: 800 }}>
                                <Table>
                                    <UserListHead
                                        order={order}
                                        orderBy={orderBy}
                                        headLabel={TableHeader}
                                        rowCount={data.length}
                                        numSelected={selected.length}
                                        onRequestSort={handleRequestSort}
                                        onSelectAllClick={handleSelectAllClick}
                                        hideCheckBoxes={true}
                                    />
                                    <TableBody>
                                        {filteredUsers
                                            .slice(
                                                page * rowsPerPage,
                                                page * rowsPerPage + rowsPerPage,
                                            )
                                            .map((row) => {
                                                const {
                                                    CharacterID,
                                                    CharacterName,
                                                    Kills,
                                                    Side,
                                                    Class,
                                                    Rank,
                                                } = row;
                                                const isItemSelected =
                                                    selected.indexOf(CharacterID) !== -1;

                                                let cellStyling = null;

                                                // if (Rank >= 4 && Rank <= 10)
                                                //     cellStyling = {
                                                //         color: palette.text.primary,
                                                //         textShadow:
                                                //             '1px 1px 2px #e94535, 0 0 1em white, 0 0 0.2em white',
                                                //         fontWeight: 'bold',
                                                //         fontSize: '0.9em',
                                                //     };

                                                return (
                                                    <>
                                                        <TableRow
                                                            hover
                                                            key={CharacterID}
                                                            tabIndex={-1}
                                                            role="checkbox"
                                                            selected={isItemSelected}
                                                            aria-checked={isItemSelected}
                                                            style={{
                                                                backgroundColor:
                                                                    palette.background.hover,
                                                                textDecoration: 'none',
                                                            }}
                                                            onClick={() => handleClick(CharacterID)}
                                                        >
                                                            <TableCell padding="checkbox"></TableCell>
                                                            <TableCell align="left">
                                                                <div
                                                                    style={{
                                                                        //height: '40px',
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        //gap: '30px',
                                                                    }}
                                                                >
                                                                    <span
                                                                        style={{
                                                                            width: '35px',
                                                                            marginRight: '0.5rem',
                                                                            textShadow: `1px 1px 2px ${SETTINGS.CHEST_IMAGES[selector]?.[Rank]?.backgroundColor}, 0 0 1em ${SETTINGS.CHEST_IMAGES[selector]?.[Rank]?.backgroundColor}, 0 0 0.2em ${SETTINGS.CHEST_IMAGES[selector]?.[Rank]?.backgroundColor}`,
                                                                            color: SETTINGS
                                                                                .CHEST_IMAGES[
                                                                                selector
                                                                            ]?.[Rank]
                                                                                ?.backgroundColor,
                                                                            fontWeight: SETTINGS
                                                                                .CHEST_IMAGES[
                                                                                selector
                                                                            ]?.[Rank]
                                                                                ? 'bold'
                                                                                : 'normal',
                                                                            fontSize: SETTINGS
                                                                                .CHEST_IMAGES[
                                                                                selector
                                                                            ]?.[Rank]
                                                                                ? '1.050em'
                                                                                : 'inherit',
                                                                        }}
                                                                    >
                                                                        {Rank}
                                                                    </span>
                                                                    {SETTINGS.CHEST_IMAGES[
                                                                        selector
                                                                    ]?.[Rank] && (
                                                                        <div>
                                                                            <RankTooltip
                                                                                chest={
                                                                                    SETTINGS
                                                                                        .CHEST_IMAGES[
                                                                                        selector
                                                                                    ][Rank].chest
                                                                                }
                                                                                image={
                                                                                    SETTINGS
                                                                                        .CHEST_IMAGES[
                                                                                        selector
                                                                                    ][Rank].image
                                                                                }
                                                                                description={
                                                                                    SETTINGS
                                                                                        .CHEST_IMAGES[
                                                                                        selector
                                                                                    ][Rank]
                                                                                        .description
                                                                                }
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </TableCell>
                                                            {/* <TableCell align="left" hidden>
                                                            {' '}
                                                            {CharacterID}
                                                        </TableCell> */}
                                                            <TableCell align="left">
                                                                {CharacterName}
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                {Kills}
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <img
                                                                    src={
                                                                        SETTINGS.FILTERS[Side]
                                                                            ?.image
                                                                    }
                                                                    alt={
                                                                        SETTINGS.FILTERS[Side]
                                                                            ?.Server
                                                                    }
                                                                    width={32}
                                                                    height={32}
                                                                />
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <img
                                                                    src={
                                                                        SETTINGS.FILTERS[Side]
                                                                            ?.Class[Class].image
                                                                    }
                                                                    alt={
                                                                        SETTINGS.FILTERS[Side]
                                                                            ?.Class[Class].label
                                                                    }
                                                                    width={32}
                                                                    height={32}
                                                                    // style={{
                                                                    //     WebkitFilter:
                                                                    //         'drop-shadow(1px 1px 1px rgba(255, 255, 255, 0.3))',
                                                                    //     filter: 'drop-shadow(1px 1px 1px rgba(255, 255, 255, 0.3))',
                                                                    // }}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell
                                                                style={{
                                                                    paddingBottom: 0,
                                                                    paddingTop: 0,
                                                                }}
                                                                colSpan={10}
                                                            >
                                                                <Collapse
                                                                    in={openRow === CharacterID}
                                                                    timeout="auto"
                                                                    unmountOnExit
                                                                >
                                                                    <Box
                                                                        sx={{
                                                                            margin: 1,
                                                                        }}
                                                                    >
                                                                        <Typography
                                                                            variant="h6"
                                                                            gutterBottom
                                                                            component="div"
                                                                        >
                                                                            Summary
                                                                        </Typography>
                                                                        {!summaryLoading &&
                                                                        !!summary ? (
                                                                            <AreaCharts
                                                                                labels={summary.map(
                                                                                    (item) =>
                                                                                        item.Date,
                                                                                )}
                                                                                data={chartData}
                                                                                type={['solid']}
                                                                            />
                                                                        ) : (
                                                                            <>
                                                                                <br />
                                                                                <br />
                                                                                <CenterLoading />
                                                                            </>
                                                                        )}
                                                                    </Box>
                                                                </Collapse>
                                                            </TableCell>
                                                        </TableRow>
                                                    </>
                                                );
                                            })}
                                        {emptyRows > 0 && (
                                            <TableRow style={{ height: 53 * emptyRows }}>
                                                <TableCell colSpan={TableHeader.length + 1} />
                                            </TableRow>
                                        )}
                                    </TableBody>

                                    {isUserNotFound && (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell
                                                    align="center"
                                                    colSpan={TableHeader.length + 1}
                                                    sx={{ py: 3 }}
                                                >
                                                    <SearchNotFound searchQuery={filterName} />
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    )}
                                </Table>
                            </TableContainer>
                        </Scrollbar>

                        {/* <TablePagination
                            rowsPerPageOptions={SETTINGS.rowsPerPageList}
                            component="div"
                            count={data.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        /> */}
                    </Card>
                </>
            )}
        </>
    );
};
