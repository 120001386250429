import { styled } from '@mui/material/styles';

export const GemsAvatar = styled('img')({
    top: 0,
    width: '90%',
    height: '60%',
    objectFit: 'contain',
    position: 'absolute',
    borderRadius: 12,
    margin: '5%',
    marginBottom: 0,
    // boxShadow: 'rgba(0,0,0,0.8) 0 0 10px',
});
