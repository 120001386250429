import PropTypes from 'prop-types';
// material
import { Box } from '@mui/material';
import { SETTINGS } from 'src/config/settings';

// ----------------------------------------------------------------------

GemImage.propTypes = {
    sx: PropTypes.object,
};

export default function GemImage({ sx }) {
    return (
        <Box
            margin="auto"
            component="img"
            src={SETTINGS.GemImage}
            sx={{ width: 30, height: 'auto', ...sx }}
        />
    );
}
